import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { Profile } from '../../domain/types/Profile'
import { Table, Avatar, Space, Input, Breakpoint, Grid, Segmented } from 'antd'
import { useMyNetworkProfiles } from '../../data/services/conversation/conversationService'
import { MoreOutlined, RightOutlined } from '@ant-design/icons'
import MyNetworkFiltersView from '../components/MyNetworkFiltersView'
import {
  CloseIcon,
  FilterIcon,
  CheckBoxIcon,
  CheckedBoxIcon,
} from '../common/utilComponents'
import { Company, InvestmentFirm } from '@wealthvp/javascript-sdk'
import TagContainer from '../components/Tag/TagContainer'
import InvestorProfileBadge from '../../../assets/InvestorProfileBadge.svg'
import CompanyProfileBadge from '../../../assets/CompanyProfileBadge.svg'
import RealEstateProfileBadge from '../../../assets/RealEstateProfileBadge.svg'
import TableViewIcon from '../../../assets/TableViewIcon.svg'
import CardViewIconActive from '../../../assets/CardViewIconActive.svg'
import CardViewIconInactive from '../../../assets/CardViewIconInactive.svg'
import TableViewIconInactive from '../../../assets/TableViewIconInactive.svg'
import { useNavigate } from 'react-router-dom'

type TableRow = {
  key: string
  avatar: string
  name: string
  title: string
  business: string
  type: string
  industryFocus?: string[]
}

export function MyNetwork() {
  const { loading, networkProfiles } = useMyNetworkProfiles()
  const { profile } = useProfileStore()
  const [searchKeyValue, setSearchKeyValue] = useState<string>('')
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null)
  const [searchKey, setSearchKey] = useState<string>('')
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [layout, setLayout] = useState<'card' | 'table'>('table')
  const [filters, setFilters] = useState({
    type: [] as string[],
    businessModel: [] as string[],
    investorType: '',
    industryFocus: '',
  })
  const [filteredNetworkData, setFilteredNetworkData] = useState<TableRow[]>()

  const handleKeyDown = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setSearchKeyValue(value)

    // Clear the previous timer
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }

    // Set a new timer
    debounceTimeout.current = setTimeout(() => {
      setSearchKey(value)
    }, 300) // 300 ms delay
  }

  useEffect(() => {
    console.log(networkProfiles, 'networkProfiles')
    let filteredData = networkProfiles.map((profile) => ({
      key: profile.db_ref_id,
      avatar: profile.photoURL || '',
      name: profile.displayName || '',
      title: profile.title || '',
      business: profile.organisationName || '',
      type: profile.orgType || '',
      industryFocus: (profile.organization as Company)?.industries,
      organization: profile.organization,
    }))

    // Apply search filter
    if (searchKey) {
      filteredData = filteredData.filter((cardData) => {
        const { title, business, name } = cardData
        const normalize = (str: string) =>
          str.toLowerCase().replace(/[\s\W_]+/g, '')
        const normalizedSearchKey = normalize(searchKey)

        return (
          normalize(title).includes(normalizedSearchKey) ||
          normalize(business).includes(normalizedSearchKey) ||
          normalize(name).includes(normalizedSearchKey)
        )
      })
    }

    if (
      filters.type.length > 0 ||
      filters.businessModel.length > 0 ||
      filters.investorType ||
      filters.industryFocus
    ) {
      filteredData = filteredData.filter((cardData) => {
        const { type, business, organization } = cardData
        const matchesType =
          filters.type.length === 0 || filters.type.includes(type)
        const matchesBusinessModel =
          filters.businessModel.length === 0 ||
          (business &&
            filters.businessModel.some((filterValue) =>
              (organization as Company)?.businessModal?.includes(filterValue),
            ))
        const matchesInvestorType =
          !filters?.investorType ||
          filters?.investorType ===
            (organization as InvestmentFirm)?.investorType
        const matchesIndustryFocus =
          !filters.industryFocus ||
          (organization as Company)?.industries?.includes(filters.industryFocus)

        return (
          matchesType &&
          matchesBusinessModel &&
          matchesInvestorType &&
          matchesIndustryFocus
        )
      })
    }

    setFilteredNetworkData(filteredData)
  }, [networkProfiles, searchKey, filters])

  if (loading) return <div>Loading...</div>

  return (
    <div className="body_layout">
      <div className="my_network_header">
        <div className="filter-wrap">
          <Segmented
            defaultValue="table"
            options={[
              {
                label: (
                  <div className="flex flex-row items-center gap-4">
                    <img
                      src={
                        layout === 'card'
                          ? CardViewIconActive
                          : CardViewIconInactive
                      }
                      alt="Card View"
                    />
                    <div>Card</div>
                  </div>
                ),
                value: 'card',
              },
              {
                label: (
                  <div className="flex flex-row items-center gap-4">
                    <img
                      src={
                        layout === 'table'
                          ? TableViewIcon
                          : TableViewIconInactive
                      }
                      alt="Table View"
                    />
                    <div>Table</div>
                  </div>
                ),
                value: 'table',
              },
            ]}
            onChange={(value) => {
              setLayout(value as 'card' | 'table')
            }}
            className="segmented-custom"
          />
          <div className="search_div">
            <Input
              className="search-input-field"
              placeholder="Search Network"
              type="text"
              allowClear
              value={searchKeyValue}
              onChange={handleKeyDown}
            />
            <div
              className="filter_icon"
              onClick={() => {
                setShowFilters(!showFilters)
              }}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setShowFilters(!showFilters)
                }
              }}
            >
              <FilterIcon />
            </div>
            <div className="more_icon">
              <MoreOutlined className="text-xl" />
            </div>
            <div
              className={`explore-filter-panel ${showFilters ? 'show' : 'hide'}`}
            >
              <div className="filter_panel_flex">
                <div className="filter_header">
                  <h3>Filters</h3>
                  <CloseIcon
                    onClick={() => {
                      setShowFilters(false)
                    }}
                  />
                </div>

                <MyNetworkFiltersView
                  filters={filters}
                  setFilters={setFilters}
                  setShowFilters={setShowFilters}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {layout === 'card' ? (
        <></>
      ) : (
        <TableView tableData={filteredNetworkData} profile={profile} />
      )}
    </div>
  )
}

export interface ITableViewProps {
  tableData?: TableRow[]
  profile: Profile | null
}

export default function TableView(props: ITableViewProps) {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const navigateTo = useNavigate()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const xs = screens.xs
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_text, record) => {
        return (
          <Space
            size="middle"
            onClick={() =>
              navigateTo(`/mynetwork/${record.key}`, {
                state: { orgType: record.type },
              })
            }
            className="cursor-pointer"
          >
            <div className="avatar-wrapper">
              <Avatar src={record.avatar} size="large">
                {record.name.charAt(0)}
              </Avatar>
              {xs && ( // Only render on mobile
                <div className="small-avatar">
                  <Avatar
                    src={
                      record.type === 'COMPANY'
                        ? CompanyProfileBadge
                        : record.type === 'INVESTOR'
                          ? InvestorProfileBadge
                          : record.type === 'REALESTATE'
                            ? RealEstateProfileBadge
                            : undefined
                    }
                    size="large"
                  />
                </div>
              )}
            </div>
            <div>
              <div className="font-bold">{record.name}</div>
              <div className="text-gray text-sm">{record.title}</div>
            </div>
          </Space>
        )
      },
    },
    {
      title: 'Business',
      dataIndex: 'business',
      key: 'business',
      responsive: ['md'] as Breakpoint[],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      responsive: ['md'] as Breakpoint[],
      render: (type: string) =>
        type
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase()),
    },
    {
      title: 'Industry Focus',
      dataIndex: 'industryFocus',
      key: 'industryFocus',
      responsive: ['md'] as Breakpoint[],
      render: (industryFocus: string[] | undefined) =>
        Boolean(industryFocus?.length) && (
          <TagContainer
            tags={industryFocus?.map((industry) =>
              industry
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()),
            )}
          />
        ),
    },
    {
      key: 'action',
      render: () => {
        if (screens.xs) {
          return <RightOutlined />
        } else {
          return <MoreOutlined className="text-xl" />
        }
      },
    },
  ]

  const handleSelectAll = () => {
    if (selectedRowKeys.length === props.tableData?.length) {
      // Deselect all
      setSelectedRowKeys([])
    } else {
      // Select all
      setSelectedRowKeys(props.tableData?.map((row) => row.key) || [])
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys)
    },
    columnTitle: (
      <div
        className="custom-checkbox-wrapper"
        onClick={() => handleSelectAll()}
        role="checkbox"
        aria-checked={selectedRowKeys.length === props.tableData?.length}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleSelectAll()
          }
        }}
      >
        {selectedRowKeys.length === props.tableData?.length ? (
          <CheckedBoxIcon />
        ) : (
          <CheckBoxIcon />
        )}
      </div>
    ),
  }

  const handleCheckboxToggle = (key) => {
    setSelectedRowKeys((prevSelectedRowKeys) =>
      prevSelectedRowKeys.includes(key)
        ? prevSelectedRowKeys.filter((selectedKey) => selectedKey !== key)
        : [...prevSelectedRowKeys, key],
    )
  }

  return (
    <div className="my-network-table">
      <Table
        dataSource={props.tableData}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowSelection={{
          ...rowSelection,
          renderCell: (checked, record) => (
            <div
              className="custom-checkbox-wrapper"
              onClick={(e) => {
                e.stopPropagation()
                handleCheckboxToggle(record.key)
              }}
              role="checkbox"
              aria-checked={checked}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.stopPropagation()
                  handleCheckboxToggle(record.key)
                }
              }}
            >
              {checked ? <CheckedBoxIcon /> : <CheckBoxIcon />}
            </div>
          ),
        }}
      />
    </div>
  )
}
