import React from 'react'
import { colors } from '../../common/colors'
import SocialIcons from '../SocialIconsView'
import { TeamMember as TeamMemberType } from '../../../domain/types/Company'

interface TeamMemberProps {
  teamMember: TeamMemberType
  condensed?: boolean
  hideSocialIcons?: boolean
  titleColor?: string
}

export const TeamMember: React.FC<TeamMemberProps> = ({
  teamMember,
  hideSocialIcons,
}) => {
  const navigateToSocialLink = () => {
    if (teamMember.socialLink) {
      window.open(teamMember.socialLink, '_blank')
    }
  }
  return (
    <div
      className="flex flex-col items-center text-center"
      role="button"
      tabIndex={0}
      onClick={navigateToSocialLink}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          navigateToSocialLink()
        }
      }}
    >
      {teamMember.photoUrl ? (
        <img
          src={teamMember.photoUrl}
          alt={teamMember.displayName}
          className="rounded-full w-24 h-24 object-cover"
        />
      ) : (
        <div
          className={`rounded-full w-24 h-24 ${
            colors[Math.floor(Math.random() * colors.length)]
          }`}
        />
      )}
      <p className="mt-2 font-semibold text-lg">{teamMember.displayName}</p>
      {teamMember.title ? (
        <p className="text-gray text-base">{teamMember.title}</p>
      ) : (
        <div className="h-6" />
      )}
      <p className="text-base text-white mb-1">{teamMember.description}</p>
      {!hideSocialIcons && teamMember.socialLink && (
        <SocialIcons iconsList={[teamMember.socialLink]} />
      )}
    </div>
  )
}
