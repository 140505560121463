import { Divider } from 'antd'
import { Company } from '../../domain/types/Company'
import TagContainer from './Tag/TagContainer'
import { convertNumbertoCurrency } from '../common/utils'
import { formatAllCapsSnakeToReadableStructure } from '../common/formatter'
import Thumbnail from './Thumbnail/Thumbnail'
import { useNavigate } from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons'

interface CompanySummaryProps {
  company?: Company
  cardStatusAction?: () => JSX.Element
}

const CompanySummary: React.FC<CompanySummaryProps> = ({
  company,
  cardStatusAction,
}) => {
  const navigateTo = useNavigate()
  const useOfFunds = company?.useOfFunds
    ?.map((structure) => formatAllCapsSnakeToReadableStructure(structure))
    .join(', ')

  const companyRoute = `/explore/company/${company?.id}/${company?.name}`
  const companyName = company?.name || ''
  const companyLocation = `${company?.city}, ${company?.state}`
  const companyValuation = company?.valueProposition || ''
  const isMobile = window.innerWidth <= 768

  return (
    <div className="p-0 pt-8 pb-44 md:p-4">
      {/* Mobile View */}
      <div className="bg-black p-8 rounded-lg w-full block md:hidden">
        <div className="flex items-center space-x-3">
          <div className="flex items-center justify-center w-28 h-28">
            <Thumbnail imageUrl={company?.logoUri} title={companyName} />
          </div>
          <div>
            <h2 className="text-lg font-semibold break-words">{companyName}</h2>
            <p className="text-gray text-sm">{companyLocation}</p>
          </div>
          <div className="flex justify-end flex-1">
            <RightOutlined
              className="text-white"
              onClick={() => navigateTo(companyRoute)}
            />
          </div>
        </div>
        <div className="mt-4 text-sm grid grid-cols-3 gap-2">
          <div className="flex flex-col">
            <p className="text-gray text-sm">Model</p>
            <p>{company?.businessModal}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray text-sm">Founded</p>
            <p>{company?.founded}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray text-sm">Employees</p>
            <p>{company?.employeeCount}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray text-sm">Valuation</p>
            <p>{company?.valuation}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray text-sm">Raised</p>
            <p>{company?.fundingRaisedToDate}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray text-sm">Revenue</p>
            <p>{company?.annualRevenue}</p>
          </div>
        </div>
        <div className="mt-4">
          <p className="text-gray text-sm">Use of Funds</p>
          <p>{useOfFunds}</p>
        </div>
        <div className="mt-4">
          <TagContainer
            titleFontSize={'text-sm'}
            tagListTitle={'Industry Focus'}
            bold={false}
            tags={company?.industries?.map((industry) =>
              industry
                .replace(/_/g, ' ')
                .toLowerCase()
                .replace(/\b\w/g, (char) => char.toUpperCase()),
            )}
          />
        </div>
        <div className="mt-4 text-sm">
          <p className="text-gray">Value Proposition</p>
          <div
            className="text-white"
            dangerouslySetInnerHTML={{
              __html: companyValuation,
            }}
          />
        </div>
        <div className="message-box">
          <div className="org-status-action">
            {cardStatusAction ? cardStatusAction() : <></>}
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className="bg-black p-8 rounded-lg w-full hidden md:block">
        <div className="rounded-lg w-full">
          <div className="flex flex-col md:flex-row items-center md:items-start justify-between rounded-lg w-full">
            {/* Logo and Name */}
            <div className="flex flex-col md:flex-row items-center gap-4 flex-grow">
              <div className="flex items-center justify-center w-28 h-28">
                <Thumbnail imageUrl={company?.logoUri} title={companyName} />
              </div>
              <div className="flex flex-col flex-grow text-center md:text-left">
                <div className="flex justify-between items-center w-full">
                  <div className="flex flex-row gap-4 items-center">
                    <h3 className="text-xl font-semibold text-white">
                      {companyName}
                    </h3>
                    <Divider
                      type="vertical"
                      className="bg-white hidden md:block"
                    />
                    <p className="text-sm text-gray">{companyLocation}</p>
                  </div>
                  <button
                    className="text-yellow-green text-sm bg-transparent border-none cursor-pointer p-0"
                    onClick={() => navigateTo(companyRoute)}
                  >
                    View Company
                  </button>
                </div>
                {/* Metadata */}
                <div
                  className={`${isMobile ? 'grid grid-cols-3' : 'flex flex-wrap'} text-left gap-2 w-full mt-4`}
                >
                  <div className="flex-1 min-w-[120px]">
                    <p className="text-gray text-sm">Model</p>
                    <p className="text-white">{company?.businessModal}</p>
                  </div>
                  <div className="flex-1 min-w-[120px]">
                    <p className="text-gray text-sm">Valuation</p>
                    <p className="text-white">
                      {convertNumbertoCurrency(company?.valuation) || 'N/A'}
                    </p>
                  </div>
                  <div className="flex-1 min-w-[120px]">
                    <p className="text-gray text-sm">Raised</p>
                    <p className="text-white">
                      {convertNumbertoCurrency(company?.fundingRaisedToDate)}
                    </p>
                  </div>
                  <div className="flex-1 min-w-[120px]">
                    <p className="text-gray text-sm">Revenue</p>
                    <p className="text-white">
                      {convertNumbertoCurrency(company?.annualRevenue)}
                    </p>
                  </div>
                  <div className="flex-1 min-w-[120px]">
                    <p className="text-gray text-sm">Founded</p>
                    <p className="text-white">{company?.founded}</p>
                  </div>
                  <div className="flex-1 min-w-[120px]">
                    <p className="text-gray text-sm">Employee</p>
                    <p className="text-white">{company?.employeeCount}</p>
                  </div>
                  <div className="flex-[2] min-w-[240px]">
                    <p className="text-gray text-sm">Use of Funds</p>
                    <p className="text-white">{useOfFunds}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Industry Focus Tags */}
        <TagContainer
          titleFontSize={'text-lg text-gray'}
          tagListTitle={'Industry Focus'}
          tags={company?.industries?.map((industry) =>
            industry
              .replace(/_/g, ' ')
              .toLowerCase()
              .replace(/\b\w/g, (char) => char.toUpperCase()),
          )}
        />

        {/* Value Proposition */}
        <div className="mt-6">
          <p className="text-gray text-sm">Value Proposition</p>
          <div
            className="text-white"
            dangerouslySetInnerHTML={{
              __html: companyValuation,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CompanySummary
