import { useEffect, useState } from 'react'
import { Button, ConfigProvider, Input, Select } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllProfilesByType } from '../../../data/services/profile/profileService'
import { formatTimestamp } from '../../../data/services/explore/converters'
import { Profile } from '../../../domain/types/Profile'

export type OrgSelection = {
  id: string
  filter: string
}

export function Accounts({
  onSelectAccount,
  selectedOrg,
}: {
  onSelectAccount?: (OrgSelection) => void
  selectedOrg?: OrgSelection | null
}) {
  const navigateTo = useNavigate()
  const { orgtype } = useParams<{ orgtype: string }>()
  const [filterValue, setFilterValue] = useState<string>(orgtype || 'COMPANY')
  const [accounts, setAccounts] = useState<Profile[]>([])
  const [oAccounts, setOaccount] = useState<Profile[]>([])

  useEffect(() => {
    if (filterValue) {
      loadUsers(filterValue)
    }
  }, [filterValue, selectedOrg])

  const columns: ColumnsType<Profile> = [
    {
      title: 'Organisation Name',
      dataIndex: 'organisationName',
      key: 'organisationName',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Created Date',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (createdOn) => {
        return <div>{formatTimestamp(createdOn)}</div>
      },
    },
    {
      title: 'Status',
      dataIndex: 'userStatus',
      key: 'userStatus',
    },
  ]

  const loadUsers = (filterValue: string) => {
    getAllProfilesByType(filterValue).then((res: Profile[]) => {
      setOaccount(res)
      setAccounts(res)
    })
  }

  const onsearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value
    if (currValue.trim()) {
      const filteredData = oAccounts.filter((entry) =>
        entry?.organisationName
          ?.toLowerCase()
          .includes(currValue.toLowerCase()),
      )
      setAccounts(filteredData)
    } else {
      setAccounts(oAccounts)
    }
  }

  const handleChange = (value: string) => {
    setFilterValue(value)
  }

  return (
    <div className="">
      <div className=" accounts_view body_layout">
        <div className="header_view">
          <Input placeholder="Search Name" onChange={(e) => onsearch(e)} />
          <Button onClick={() => navigateTo('/admin/createAccount')}>
            New Account
          </Button>
          <Select
            defaultValue={filterValue}
            onChange={handleChange}
            options={[
              { value: 'COMPANY', label: 'Company' },
              { value: 'INVESTOR', label: 'Investment Firms' },
              { value: 'REALESTATE', label: 'Real Estate' },
            ]}
          />
        </div>

        <ConfigProvider
          theme={{
            components: {
              Table: {
                rowHoverBg: '#0f1217',
              },
            },
          }}
        >
          <Table
            dataSource={accounts}
            columns={columns}
            key="table"
            onRow={(record) => {
              return {
                onClick: () => {
                  if (onSelectAccount)
                    onSelectAccount({
                      id: record.organisationId,
                      filter: filterValue,
                    })
                },
              }
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  )
}
