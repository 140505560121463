import {
  UserCredential,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  createUserWithEmailAndPassword,
} from 'firebase/auth'
import FirebaseApi from '../../../../api/firebaseApi'
import { disableNoFormAiChatbot } from '../../../presentation/common/utils'

const auth = FirebaseApi.auth

/*
    Wraper function to Firebase sign in with email and password,
    Should be able to swap out with any other auth provider 
*/
export function signInWithEmailPassword({
  email,
  password,
}: {
  email: string
  password: string
}): Promise<UserCredential> {
  return signInWithEmailAndPassword(auth, email, password)
}

export function onUserAuthStateChange(callback: (user: User | null) => void) {
  onAuthStateChanged(auth, callback)
}

export function getCurrentUser(): User | null {
  return auth.currentUser
}

export function signUpWithEmailAndPassword(
  email: string,
  password: string,
): Promise<UserCredential> {
  return createUserWithEmailAndPassword(auth, email, password)
}

export function signOutUser(): Promise<void> {
  return signOut(auth)
    .then(() => {
      disableNoFormAiChatbot() // Remove chatbot script after logout
      window.location.reload() //to optimize later
    })
    .catch((error) => {
      console.log('Error signing out:', error)
    })
}
