import toast from './Toast'

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const replaceWithBr = (value) => {
  if (value) return value.replace(/\n/g, '<br />')
}

type DDLItem = { value: string; label: string }

export const getFileInfoByURL = (url: string) => {
  url = decodeURIComponent(url)
  const filePath = url.split('?')[0],
    fileExt = filePath.split('.').pop(),
    fileName = filePath.split('/').pop()?.split('--').pop(),
    fileStorePath = filePath.split('/').splice(-2).join('/')
  return {
    filePath,
    fileExt,
    fileName,
    fileStorePath,
  }
}

export const convertToDDLData = ({
  data,
  labelField,
  valueField,
  addNone = false,
}: {
  data: object[]
  labelField: string
  valueField: string
  addNone?: boolean
}): DDLItem[] => {
  const res = data.map((obj) => {
    return { label: obj[labelField], value: obj[valueField] }
  })
  return !addNone ? res : [{ label: 'None', value: null }, ...res]
}

export const cleanObject = (obj) => {
  const newObj = {}

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== null &&
      obj[key] !== undefined
    ) {
      newObj[key] = obj[key]
    }
  }
  return newObj
}

export const convertNumbertoCurrency = (num?: number | string): string => {
  return num ? '$' + String(num)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ''
}

export const getUrlParameterValue = (parameter: string): string => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(parameter) ?? ''
}

export const getStrings = (selArr, arr, id, value) => {
  if (selArr && selArr?.length !== 0 && arr && arr.length !== 0) {
    const selInds = getSelStrings(selArr, arr, id, value)
    return selInds
  }
}

export const getSelStrings = (
  selectedArr: string[],
  arr,
  id,
  value,
): string[] => {
  if (selectedArr && selectedArr?.length !== 0 && arr && arr.length !== 0) {
    return arr
      .filter((obj) => selectedArr?.indexOf(obj[id]) !== -1)
      .map((ind) => {
        return ind[value]
      })
  } // returns value

  return []
}

export const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value).then(
    () => {
      // openNotification(`Copied to clipboard`, notificationTypes.success);
      toast.success('Copied to clipboard', '')
    },
    () => {
      console.error('Failed to copy')
      // openNotification(`Failed to copy `, notificationTypes.error);
      toast.error('Failed to copy ', '')
    },
  )
}

export const getVideoEmbedUrl = (url) => {
  let embedUrl
  // YouTube URL patterns
  const youtubeRegex =
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i
  // Vimeo URL patterns
  const vimeoRegex =
    /(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:\w+\/)?|album\/(?:\w+\/)?|video\/|)(\d+)(?:$|\/|\?))/

  if (youtubeRegex.test(url)) {
    const videoId = url.match(youtubeRegex)[1]
    embedUrl = `https://www.youtube.com/embed/${videoId}`
  } else if (vimeoRegex.test(url)) {
    const videoId = url.match(vimeoRegex)[1]
    embedUrl = `https://player.vimeo.com/video/${videoId}`
  } else {
    console.error('Invalid video URL')
  }

  return embedUrl
}

export const isMobile = window.innerWidth <= 768

export const shortenCurrency = (value: number | string): string => {
  const num = typeof value === 'string' ? parseFloat(value) : value
  if (isNaN(num)) return value.toString()

  if (num >= 1e6) {
    return (num / 1e6).toFixed(1) + 'M'
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + 'K'
  } else {
    return num.toString()
  }
}

export const enableNoFormAiChatbot = () => {
  if (!document.getElementById('chatbot-initials-script')) {
    const scriptTag = document.createElement('script')
    scriptTag.id = 'chatbot-initials-script'
    scriptTag.src = 'https://chat-bbl.noform.ai/script-chatbot.js'
    scriptTag.defer = true
    scriptTag.type = 'text/javascript'
    scriptTag.setAttribute('data-server-url', 'https://app.noform.ai')
    scriptTag.setAttribute('data-bot-id', '679aa1bf83bc14ece9d2aab0')
    scriptTag.setAttribute('crossorigin', 'anonymous')

    // Handle script loading errors
    scriptTag.onerror = () => {
      console.error('Failed to load the chatbot script.')
    }

    document.body.appendChild(scriptTag)
  }
}

export const disableNoFormAiChatbot = () => {
  // Remove the chatbot script
  const chatBotScript = document.getElementById('chatbot-initials-script')
  if (chatBotScript) {
    chatBotScript.remove()
  }

  // Remove the chatbot iframe by ID
  const iframe = document.getElementById('botscrew-widget')
  if (iframe) {
    iframe.remove()
  }

  // In case the chatbot injects other iframes dynamically
  // const dynamicIframe = document.querySelector(
  //   'iframe[src*="chat-bbl.noform.ai"]',
  // )
  // if (dynamicIframe) {
  //   dynamicIframe.remove()
  // }
}
