import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CompanyMetaData } from '../components/CompanyMetaData/CompanyMetaData'
import { getProfileById } from '../../data/services/profile/profileService'
import { Profile } from '../../domain/types/Profile'
import { getCompanyById } from '../../data/services/company/companyService'
import { Company } from '../../domain/types/Company'
import CompanySummary from '../components/CompanySummary'
import { BackArrow } from '../common/utilComponents'
import { Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { createConvoByProfiles } from '../../data/services/conversation/conversationService'
import useProfileStore from '../../data/appState/profileStore'
import { getInvestmentFirmById } from '../../data/services/investor/investorService'
import { InvestmentFirm } from '../../domain/types/Investor'
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView'

export const MyNetworkUserProfile = () => {
  const { id } = useParams<{ id: string }>()
  const { profile } = useProfileStore()
  const [userProfile, setProfile] = useState<Profile>()
  const [company, setCompany] = useState<Company>()
  const [investor, setInvestor] = useState<InvestmentFirm>()
  const [loading, setLoading] = useState(true)
  const navigateTo = useNavigate()
  const metaDataAboutField =
    userProfile?.orgType === 'COMPANY'
      ? userProfile?.about
      : `${userProfile?.title} | ${userProfile?.role}`
  useEffect(() => {
    if (id) {
      getProfileById(id).then((profile) => {
        setProfile(profile)
      })
    }
  }, [id])

  useEffect(() => {
    if (userProfile?.organisationId) {
      if (userProfile.orgType === 'COMPANY') {
        getCompanyById(userProfile?.organisationId).then((company) => {
          setCompany(company)
          setLoading(false)
        })
      } else if (userProfile.orgType === 'INVESTOR') {
        getInvestmentFirmById(userProfile?.organisationId).then((investor) => {
          setInvestor(investor)
          console.log('investor', investor)
          setLoading(false)
        })
      }
    }
  }, [userProfile])

  const gotoConversation = () => {
    if (userProfile && profile && profile.uid) {
      createConvoByProfiles([userProfile, profile], profile?.uid).then(
        (convoid) => {
          navigateTo(`/chat/type/primary/${convoid}`)
        },
      )
    }
  }

  const cardStatusAction = () => {
    return (
      <div className="py-2 w-full">
        <Button
          className="message-button sm:hidden w-80"
          onClick={gotoConversation}
        >
          Message
        </Button>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="loader-container">
        <Spin size="large" indicator={<LoadingOutlined spin />} />
      </div>
    )
  }
  console.log(userProfile)
  return (
    <div className="p-8 pt-2 sm:pt-8">
      <div className="flex justify-between items-center my-2 md:my-8">
        <div className="flex items-center gap-2">
          <BackArrow
            onClick={() => {
              navigateTo(-1)
            }}
          />
          <h2>Back</h2>
        </div>
        <Button
          className="message-button hidden sm:block"
          onClick={gotoConversation}
        >
          Message
        </Button>
      </div>
      {userProfile?.orgType === 'COMPANY' && (
        <>
          <CompanyMetaData
            socialLinks={userProfile?.socialLinks}
            logoUrl={userProfile?.photoURL}
            name={userProfile?.firstName + ' ' + userProfile?.lastName}
            variant="user"
            about={metaDataAboutField}
            location={`${userProfile?.city}, ${userProfile?.state}`}
            position={userProfile?.role}
          />
          <CompanySummary
            company={company}
            cardStatusAction={cardStatusAction}
          />
        </>
      )}
      {userProfile?.orgType === 'INVESTOR' && investor && (
        <ExpandedInvestmentFirmView
          investmentFirm={investor}
          cardStatusAction={cardStatusAction}
          isConnected={true}
        />
      )}
    </div>
  )
}
