import * as React from 'react'
import { Profile } from '../../../domain/types/Profile'
import { UserGroupIcon } from '../../common/utilComponents'
import ColorCard from '../ColorCard'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import useProfileStore from '../../../data/appState/profileStore'
import { readableTime } from '../../../data/services/explore/converters'
import { Link, useParams } from 'react-router-dom'
import { ConnectionType } from '../../../domain/types/Connection'
import { ExploreCardData } from '../../../data/services/explore/ExploreCardData'
import { Conversation, ConvoMessage } from '../../../domain/types/Conversation'

export interface IChatListItemProps {
  convo: Conversation
  networkProfiles: Profile[]
  convoOrgs: ExploreCardData[]
}

export function ChatListItem({
  convo,
  networkProfiles,
  convoOrgs,
}: IChatListItemProps) {
  const { profile, unReadConvoIds } = useProfileStore()
  const { type: paramfilterType } = useParams() // primary , requests
  const { id: paramConvoId } = useParams()

  let convoAvatar = (
    <Avatar
      shape="circle"
      size={36}
      src={<UserOutlined style={{ color: '#44475b' }} />}
    />
  )

  const isGroupChat: boolean = convo.connectionType === ConnectionType.ORG_ORG
  if (isGroupChat) {
    const convoOrgId: string | undefined =
      profile?.organisationId === convo.toOrgId
        ? convo.fromOrgId
        : convo.toOrgId

    const convoOrgInfo: ExploreCardData | undefined = convoOrgs.find(
      (org) => org.id === convoOrgId,
    )
    if (convoOrgInfo) {
      if (convoOrgInfo?.imageUrl)
        convoAvatar = (
          <Avatar shape="circle" size={36} src={convoOrgInfo?.imageUrl} />
        )
      else
        convoAvatar = <ColorCard name={convoOrgInfo?.title?.charAt(0) || 'O'} />
    }
  } else {
    const convoProfileId =
      profile?.uid === convo.fromUserId ? convo.toUserId : convo.fromUserId

    const convoProfile = networkProfiles.find(
      (profile) => profile.uid === convoProfileId,
    )

    if (convoProfile) {
      if (convoProfile.photoURL) {
        convoAvatar = (
          <Avatar shape="circle" size={36} src={convoProfile?.photoURL} />
        )
      } else {
        const title = convo.title || 'U'
        convoAvatar = <ColorCard name={title?.charAt(0)} />
      }
    }
  }

  const lastMessage: ConvoMessage = convo.lastMessage || ({} as ConvoMessage)

  const hasUnread =
    lastMessage && convo.db_ref_id && unReadConvoIds.includes(convo.db_ref_id)

  let lastMessagesCreatedByName = ''

  if (lastMessage) {
    lastMessagesCreatedByName =
      lastMessage?.createdById === profile?.uid
        ? 'You'
        : isGroupChat && lastMessage?.createdByName
          ? lastMessage?.createdByName
          : ''

    if (lastMessagesCreatedByName.length > 0) {
      lastMessagesCreatedByName += ': '
    }
  }

  if (
    lastMessage?.createdById &&
    !convo[lastMessage.createdById] &&
    convo.connectionType === 'USER_USER'
  ) {
    lastMessage.text = ''
    lastMessagesCreatedByName = ''
  }

  const isActive = paramConvoId === convo.db_ref_id

  let isMultiUsers = false
  if (convo?.connectionKey && !isGroupChat)
    isMultiUsers = convo?.connectionKey?.split('_').length > 2

  let chatType = paramfilterType ?? 'primary'
  if (chatType !== 'primary' && chatType !== 'requests') {
    chatType = 'primary'
  }
  const convoOrgId: string | undefined =
    profile?.organisationId === convo.toOrgId ? convo.fromOrgId : convo.toOrgId

  const primaryTitle = isMultiUsers ? 'Group Message' : convo.title

  let secondaryTitle = convo.title

  if (!isGroupChat && !isMultiUsers) {
    const otherProfileId: string | undefined = convo.connectionKey
      ?.split('_')
      .find((uid) => {
        return uid !== profile?.uid
      })
    const orgProfile: string[] = networkProfiles
      .filter((p) => p.uid === otherProfileId)
      .map((p) => p.organisationName)
      .filter((p): p is string => p !== undefined)

    secondaryTitle = orgProfile.join(', ')
  } else if (isGroupChat && networkProfiles.length > 0) {
    const orgProfile: string[] = networkProfiles
      .filter((p) => p.organisationId === convoOrgId)
      .map((p) => p.displayName)
      .filter((p): p is string => p !== undefined)

    secondaryTitle = orgProfile.join(', ')
  }
  return (
    <Link to={`/chat/type/${chatType}/${convo.db_ref_id}`}>
      <div
        className={
          'chat_card' +
          (isActive ? ' active' : '') +
          (hasUnread ? ' hasunread' : ' hasread')
        }
      >
        <div className="relative chat_body">
          <div className="convo-avatar">{convoAvatar}</div>
          <div className="grid flex-1 grid-cols-1 user_content">
            <div
              title={primaryTitle}
              className="text-[16px] text-white font-semibold truncate sm:max-w-56"
            >
              {primaryTitle}
            </div>
            <div
              title={secondaryTitle}
              className={`${hasUnread ? 'text-white' : ''} text-[14px] text-[#a1a1a1] truncate font-medium  sm:max-w-56`}
            >
              {isGroupChat || isMultiUsers ? (
                <UserGroupIcon
                  className={`${hasUnread ? 'fill-white' : ''} pr-1 `}
                />
              ) : (
                <> </>
              )}
              {secondaryTitle}
            </div>
            <div
              className={`${hasUnread ? 'text-white' : ''} text-[12px] text-[#a1a1a1] truncate font-normal sm:max-w-56`}
            >
              {lastMessagesCreatedByName} {lastMessage?.text}
            </div>
          </div>
          <div
            className={`"absolute right-0 top-0 font-normal text-[12px] " ${hasUnread ? 'text-white' : 'text-[#a1a1a1]'}`}
          >
            {readableTime(lastMessage?.createdOn)}
          </div>
          {hasUnread && (
            <span className="absolute bottom-0 right-0 rounded-full bg-white text-center flex items-center justify-center text-cool-black text-[10px] w-3 h-3">
              {hasUnread ?? ''}
            </span>
          )}
        </div>
      </div>
    </Link>
  )
}
