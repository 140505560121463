import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useProfileStore from '../../data/appState/profileStore'
import { useEffect } from 'react'
import { Company } from '../../domain/types/Company'
import { ShimmerSocialPost } from 'react-shimmer-effects'
import { getCompanyById } from '../../data/services/company/companyService'
import { Helmet } from 'react-helmet'
import { convertTimestampsToDates } from '../../data/services/explore/converters'
import { BackArrow } from '../common/utilComponents'
import { CompanyPageContent } from './Company/CompanyPageContents'

export default function CompanyMessageView() {
  const { id: companyId } = useParams()
  const { profile } = useProfileStore()

  const navigateTo = useNavigate()

  const [company, setCompany] = React.useState<Company | null>(null)

  const orgId = companyId || profile?.organisationId

  useEffect(() => {
    if (orgId)
      getCompanyById(orgId)
        .then((data) => {
          convertTimestampsToDates(data)
          setCompany(data)
        })
        .catch((err) => console.error(err))
  }, [orgId])

  if (!profile || !company) return <ShimmerSocialPost type="both" />

  return (
    <div className="body_layout">
      <Helmet>
        <title>{`WealthVP | ${company?.legalName ?? company?.name}`}</title>
        <link
          rel="canonical"
          href="https://wealthvp.com/company/{company?.id}"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="{'WealthVP | ' + company?.legalName}"
        />
        <meta
          property="og:image"
          content="https://wealthvp.com/assets/favicon.png"
        />
        <meta
          property="og:description"
          content="{'Company profile of ' + company?.legalName}"
        />
        <meta
          property="og:url"
          content="https://wealthvp.com/company/{company?.id}"
        />
        <meta property="og:site_name" content="WealthVP" />
      </Helmet>
      <div className="sub_header">
        <div
          className="back_icon"
          role="button"
          tabIndex={0}
          onClick={() => navigateTo(-1)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') navigateTo(-1)
          }}
        >
          <BackArrow />
          <h1>Back</h1>
        </div>
      </div>

      <div className="wvp-p-flex-d">
        <div className="left-top-position">
          <div className="deck-slideshow">
            <div className="re-company-deck">
              <CompanyPageContent company={company} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
