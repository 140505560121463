import React from 'react'
import { Space, Select, Button } from 'antd'
import { CheckBoxIcon, CheckedBoxIcon } from '../common/utilComponents'
import {
  companySiteProperties,
  investorSiteProperties,
} from '../../data/services/common/siteDataService'

export default function MyNetworkFiltersView({
  filters,
  setFilters,
  setShowFilters,
}) {
  const orgTypes = [
    { name: 'Company', value: 'COMPANY' },
    { name: 'Investor', value: 'INVESTOR' },
    { name: 'Real Estate', value: 'REALESTATE' },
  ]

  const toggleSelection = (value: string, key: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: prevFilters[key].includes(value)
        ? prevFilters[key].filter((v) => v !== value)
        : [...prevFilters[key], value],
    }))
  }

  const handleSelectChange = (value: string, key: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }))
  }

  const { business_model: businessModel, industry_type: industryFocus } =
    companySiteProperties ?? {}
  const { investor_type: investorType } = investorSiteProperties ?? {}

  return (
    <div className="filter_menu">
      <div className="filter_lists">
        <div>
          <div className="drop-down-btn">
            <div className="filter_lable">Type</div>
            {orgTypes.map((orgType) => (
              <div key={orgType.value} className="filter_item">
                <div
                  className="label_box"
                  role="button"
                  tabIndex={0}
                  onClick={() => toggleSelection(orgType.value, 'type')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      toggleSelection(orgType.value, 'type')
                    }
                  }}
                >
                  <Space>
                    {filters.type.includes(orgType.value) ? (
                      <CheckedBoxIcon />
                    ) : (
                      <CheckBoxIcon />
                    )}
                    <div className="label_text">{orgType.name}</div>
                  </Space>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="drop-down-btn">
            <div className="filter_lable">Business Model</div>
            {businessModel.options.map((businessModel) => (
              <div key={businessModel.value} className="filter_item">
                <div
                  className="label_box"
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    toggleSelection(businessModel.value, 'businessModel')
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      toggleSelection(businessModel.value, 'businessModel')
                    }
                  }}
                >
                  <Space>
                    {filters.businessModel.includes(businessModel.value) ? (
                      <CheckedBoxIcon />
                    ) : (
                      <CheckBoxIcon />
                    )}
                    <div className="label_text">{businessModel.label}</div>
                  </Space>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="drop-down-btn">
            <div className="w-full">
              <div className="filter_lable">Investor Type</div>
              <Select
                options={investorType.options ?? []}
                className="w-[80%]"
                value={filters.investorType}
                onChange={(value) => handleSelectChange(value, 'investorType')}
                allowClear
              />
            </div>
          </div>
        </div>
        <div>
          <div className="drop-down-btn">
            <div className="w-full">
              <div className="filter_lable">Industry Focus</div>
              <Select
                options={industryFocus.options ?? []}
                className="w-[80%]"
                value={filters.industryFocus}
                onChange={(value) => handleSelectChange(value, 'industryFocus')}
                allowClear
              />
            </div>
          </div>
        </div>
      </div>
      <div className="filter_buttons">
        <Button
          type="text"
          className="reset-btn"
          onClick={() =>
            setFilters({
              type: [],
              businessModel: [],
              investorType: '',
              industryFocus: '',
            })
          }
        >
          Reset
        </Button>

        <Button
          type="primary"
          className="save-btn"
          onClick={() => setShowFilters(false)}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
