import { DocumentData, where } from 'firebase/firestore'
import { FirestoreCollectionApi } from '../../db/FireStoreApi'
import {
  ConnectionStatus,
  ConnectionType,
  ConversationType,
} from '../../../domain/types/Connection'
import { OrgType, Profile } from '../../../domain/types/Profile'

import * as profileService from '../profile/profileService'
import { ExploreCardData } from '../explore/ExploreCardData'
import { getExploreDataByOrgId } from '../explore/exploreService'
import { useEffect, useState } from 'react'
import useProfileStore from '../../appState/profileStore'
import {
  Conversation,
  ConvoMessage,
  ConvoParticipant,
} from '../../../domain/types/Conversation'
import { useMyInvitesProfiles } from '../Invites/InviteService'

const conversationService = new FirestoreCollectionApi<Conversation>(
  'conversations',
)

const conversation_participantService = (conversationId) =>
  conversationService.getSubcollectionApi<ConvoParticipant>(
    conversationId,
    `participants`,
  )

export function getConvoByKey(
  connectionKey: string,
  convoType: ConnectionType,
) {
  return conversationService.getDocsByQuery([
    where('connectionKey', '==', connectionKey),
    where('connectionType', '==', convoType),
  ])
}

export async function createConversation(
  initConvo: Conversation,
  message: ConvoMessage | null,
  participant: ConvoParticipant,
  connectionKey: string,
): Promise<DocumentData> {
  const data = {
    ...initConvo,
    createdOn: new Date(),
    connectionStatus: ConnectionStatus.PENDING,
    lastMessage: message,
    connectionKey,
    updatedAt: new Date(),
    version: '1.1.0',
  }

  const res = await conversationService.addDoc(data)

  if (res.id) {
    if (message) {
      await addMsgToConversation(res.id, message)
    }
    await addParticipantToConversation(res.id, participant)
    return res // Resolve with the result
  } else {
    throw new Error('Error creating conversation')
  }
}

export function updateConversationById(
  convoId: string,
  data: Partial<{
    connectionStatus: ConnectionStatus
    lastMessage: ConvoMessage
    updatedAt: Date
    version: string
    CancelledBy?: string
    isActive?: boolean
    acceptedByUid?: string
    acceptedByName?: string
    isArchived?: boolean
  }>,
): Promise<void> {
  return conversationService.updateDocByKey(convoId, data)
}

export function addMsgToConversation(
  convoId: string,
  message: ConvoMessage,
): Promise<[void, DocumentData]> {
  const newMessage = {
    ...message,
    createdOn: new Date(),
    updatedOn: new Date(),
  }
  const messageService = conversationService.getSubcollectionApi(
    convoId,
    'messages',
  )

  return Promise.all([
    conversationService.updateDocByKey(convoId, { lastMessage: newMessage }),
    messageService.addDoc(newMessage),
  ])
}

export function addParticipantToConversation(
  convoId: string,
  participant: ConvoParticipant,
): Promise<void> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    )
  return participantService.setDocByKey(participant.profileUid, {
    ...participant,
    createdOn: new Date(),
  })
}

export function updateParticipantToConversation(
  convoId: string,
  participant: ConvoParticipant,
): Promise<void> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    )
  return participantService.updateDocByKey(participant.profileUid, {
    ...participant,
    createdOn: new Date(),
  })
}

export function getConversationParticipants(
  convoId: string,
): Promise<ConvoParticipant[]> {
  const participantService =
    conversationService.getSubcollectionApi<ConvoParticipant>(
      convoId,
      `participants`,
    )
  return participantService.getAllDocs()
}

export function getConvoParticipantByUid(
  convoId: string,
  uid: string,
): Promise<ConvoParticipant> {
  return conversation_participantService(convoId).getDocByKey(uid)
}

export async function upsertConvoParticipant(
  convoId: string,
  participant: ConvoParticipant,
): Promise<boolean> {
  const existingParticipant = await getConvoParticipantByUid(
    convoId,
    participant.profileUid,
  )

  if (existingParticipant) {
    await updateParticipantToConversation(convoId, participant)
  } else {
    await addParticipantToConversation(convoId, participant)
  }

  try {
    // Update lastVisited against participant profile
    await profileService.setProfileConversations(
      participant.profileId,
      convoId,
      { lastVisited: new Date() },
    )
  } catch (error) {
    console.error('Error updating profile conversations', error)
  }

  return true
}

export function getOrgConversation(orgId: string): Promise<Conversation[]> {
  return conversationService.getDocsByQuery([
    where(orgId, '==', true),
    where('connectionType', '==', 'ORG_ORG'),
  ])
}

export function onConvoMessageUpdate(
  conversationId: string,
  callBack: (any) => void,
) {
  const messagesCollectionApi = conversationService.getSubcollectionApi(
    conversationId,
    'messages',
  )
  messagesCollectionApi.onCollectionUpdate(callBack)
}

export function onMyConversationsUpdate(
  profile: Profile,
  callBack: (any) => void,
) {
  const filteredCallBack = (data) => {
    const orgConvos = data.filter((c) => c.connectionType === 'ORG_ORG')

    let userConvos = []
    if (profile.uid) {
      userConvos = data.filter(
        (c) =>
          c.connectionType === 'USER_USER' &&
          c[profile.uid ?? '']?.toString() === 'true',
      )
    }

    data = [...orgConvos, ...userConvos]
    callBack(data)
  }

  conversationService.onQueryUpdate(
    profile.organisationId ? [where(profile.organisationId, '==', true)] : [],
    filteredCallBack,
  )
}

export function getConvoOrgs(
  orgs: { orgType: string; orgId: string }[],
): Promise<ExploreCardData[]> {
  const promises = orgs
    .filter((o) => o.orgId && o.orgType)
    .map((org) => getExploreDataByOrgId(org.orgId, org.orgType as OrgType))

  return Promise.all(promises)
}

export function getAllUsersOfOrgConnections(
  ConnectionsOrgIds: string[],
): Promise<Profile[][]> {
  const promises = ConnectionsOrgIds.map((orgId) =>
    profileService.getProfilesByOrgId(orgId),
  )

  return Promise.all(promises)
}

export const getOtherOrgIdByKeyProps = (
  keyProps: { [key: string]: { type: string } },

  profileOrgId: string,
) => {
  // this is to support old data format
  if (!profileOrgId) return ''

  try {
    for (const key in keyProps) {
      const value = keyProps[key]
      if (key !== profileOrgId && value.type === 'org') {
        return key
      }
    }
  } catch (error) {
    return 'unknown'
  }
}

export function useMyNetworkProfiles() {
  const { profile, orgConnections } = useProfileStore()

  const { isloaded: inviteProfilesLoaded, invitesProfiles } =
    useMyInvitesProfiles()
  const [networkProfiles, setnetworkProfiles] = useState<Profile[]>([])

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    let allProfiles: Profile[] = invitesProfiles.filter(
      (p) => p?.orgType !== 'ADMIN',
    )

    if (
      profile?.organisationId &&
      orgConnections?.length &&
      inviteProfilesLoaded
    ) {
      const profileOrgId: string = profile.organisationId
      try {
        // Get all ORG ids of the connections of type ORG_ORG
        const orgIds = orgConnections
          .filter(
            (o) =>
              o.connectionStatus === ConnectionStatus.ACCEPTED &&
              o.connectionType === ConnectionType.ORG_ORG,
          )
          .map((org) => {
            let otherOrgId =
              org.toOrgId === profile?.organisationId
                ? org.fromOrgId
                : org.toOrgId

            if (!otherOrgId && org?.keyProps) {
              otherOrgId = getOtherOrgIdByKeyProps(org.keyProps, profileOrgId)
            }
            return otherOrgId
          })
          .filter((o) => !!o)

        let connectedUserIds: string[] = orgConnections
          .filter((o) => o.connectionType === ConnectionType.USER_USER)
          .map((org) => org.connectionKey)
          .flatMap((item) => item.split('_'))
          .filter((id) => id !== profile.uid)
          .filter((o) => !!o)

        // Get all profiles of the ORG connections
        getAllUsersOfOrgConnections(orgIds).then((profiles) => {
          allProfiles = [...allProfiles, ...profiles.flat()]
          const orgProfiles: string[] = allProfiles
            .map((p) => p.uid)
            .filter((p): p is string => p !== undefined)

          // Remove the profiles of the connected users from the org profiles
          connectedUserIds = connectedUserIds.filter(
            (u) => !orgProfiles.includes(u),
          )

          if (connectedUserIds.length > 0) {
            // Get all profiles of the connected users
            profileService.getAllUsersByUIds(connectedUserIds).then((users) => {
              // Set the network profiles with the profiles of the org connections and the connected users
              setnetworkProfiles([...allProfiles, ...users])
            })
          } else {
            setnetworkProfiles(allProfiles)
          }
        })
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    } else {
      setnetworkProfiles(allProfiles)
    }
  }, [orgConnections, profile, inviteProfilesLoaded, invitesProfiles])

  return { loading, networkProfiles }
}

export function useConvoOrgs() {
  const { profile, orgConnections } = useProfileStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [convoOrgs, setConvoOrgs] = useState<ExploreCardData[]>([])

  useEffect(() => {
    if (profile?.organisationId && orgConnections?.length) {
      const convosOrgs: { orgType: string; orgId: string }[] =
        orgConnections.map((org) => {
          if (org.fromOrgId === profile.organisationId)
            return { orgType: org.toOrgType, orgId: org.toOrgId }
          else return { orgType: org.fromOrgType, orgId: org.fromOrgId }
        })

      getConvoOrgs(convosOrgs).then((orgs) => {
        setConvoOrgs(orgs)
        setLoading(true)
      })
    }
  }, [orgConnections, profile])

  return { loading, convoOrgs }
}

export function getConvoTitleByKeyProps(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  keyProps: any,
  connectionType: ConnectionType,
  profile: Profile,
) {
  // this is to support old data format
  if (!profile) return ''

  try {
    const { organisationId, uid } = profile

    if (connectionType === ConnectionType.USER_USER) {
      for (const key in keyProps) {
        const value = keyProps[key]
        if (key !== uid && value.type === 'user') {
          return value.name
        }
      }
    }

    if (connectionType === ConnectionType.ORG_ORG) {
      for (const key in keyProps) {
        const value = keyProps[key]
        if (key !== organisationId && value.type === 'org') {
          return value.name
        }
      }
    }
  } catch (error) {
    return 'unknown'
  }
}

export function getConvoTitle(convo, profile, convoOrgs, networkProfiles) {
  let convoTitle =
    profile?.organisationId === convo.toOrgId
      ? convo.fromOrgName
      : convo.toOrgName

  const isGroupChat = convo.connectionType === ConnectionType.ORG_ORG

  if (isGroupChat) {
    const convoOrgId =
      profile?.organisationId === convo.toOrgId
        ? convo.fromOrgId
        : convo.toOrgId

    const convoOrgInfo = convoOrgs.find((org) => org.id === convoOrgId)
    if (convoOrgInfo) {
      convoTitle = convoOrgInfo?.title
    }
  } else {
    convoTitle = getConvoTitleByKeyAndProfiles(
      convo.connectionKey,
      networkProfiles,
    )
  }

  if (!convoTitle && convo?.keyProps) {
    convoTitle = getConvoTitleByKeyProps(
      convo.keyProps,
      convo.connectionType,
      profile,
    )
  }
  return convoTitle
}

export function getConvoConnectionKeyByIds(ids: string[]): string {
  return ids.sort().join('_')
}

export async function createConvoByProfiles(
  convoProfiles: Profile[],
  currentProfileId: string,
): Promise<string> {
  let convoId = ''

  try {
    const convoUserIds: string[] = convoProfiles.map((p) => p.uid ?? '')
    // Create unique connection key for the conversation based on the user ids
    const connectionKey = getConvoConnectionKeyByIds(convoUserIds)

    const convos = await getConvoByKey(connectionKey, ConnectionType.USER_USER)
    if (convos?.length > 0) {
      // If conversation already exists, return the conversation id
      convoId = convos[0].db_ref_id ?? ''
    } else {
      // Create a new conversation with the selected profiles
      const currentProfile = convoProfiles.find(
        (p) => p.uid === currentProfileId,
      )
      const {
        displayName: createdByName,
        uid: createdByUid,
        orgType: fromOrgType,
        organisationId: fromOrgId,
        organisationName: fromOrgName,
      } = currentProfile || {}

      const fromUserId = createdByUid

      const initState = {
        createdByUid,
        createdByName,
        fromOrgId,
        fromOrgType,
        fromOrgName,
        connectionType: ConnectionType.USER_USER,
        type: ConversationType.DIRECT,
        ...(fromOrgId ? { [fromOrgId]: true } : {}),
        ...(fromUserId ? { [fromUserId]: true } : {}),
        fromUserId,
        fromUserName: createdByName,
        version: '1.1.0',
      } as Conversation

      for (const profile of convoProfiles) {
        initState[profile.uid ?? ''] = true
        initState[profile.organisationId ?? ''] = true
      }

      const convoParticipant: ConvoParticipant = {
        profileId: currentProfile?.id ?? '',
        profileUid: currentProfile?.uid ?? '',
        profileName: currentProfile?.displayName ?? '',
      }

      const convo = await createConversation(
        initState,
        null,
        convoParticipant,
        connectionKey,
      )
      if (convo?.id) {
        convoId = convo.id
      }
    }
  } catch (error) {
    console.error('Error creating conversation', error)
  }
  return convoId
}

export async function getNewConvoId(
  newProfile: Profile,
  profile: Profile,
): Promise<string> {
  let convoId = ''
  if (profile === null) return convoId

  try {
    const connectionKey = getConvoConnectionKeyByIds([
      newProfile.uid ?? '',
      profile.uid ?? '',
    ])
    const convos = await getConvoByKey(connectionKey, ConnectionType.USER_USER)
    if (convos?.length > 0) {
      convoId = convos[0].db_ref_id ?? ''
    } else {
      const {
        displayName: createdByName,
        uid: createdByUid,
        orgType: fromOrgType,
        organisationId: fromOrgId,
        organisationName: fromOrgName,
      } = profile || {}

      const {
        organisationId: toOrgId,
        orgType: toOrgType,
        organisationName: toOrgName,
      } = newProfile

      const toUserId = newProfile.uid,
        fromUserId = createdByUid

      const initState = {
        createdByUid,
        createdByName,
        fromOrgId,
        fromOrgType,
        fromOrgName,
        toOrgId,
        toOrgType,
        toOrgName,
        connectionKey: connectionKey,
        connectionType: ConnectionType.USER_USER,
        type: ConversationType.DIRECT,
        ...(toOrgId ? { [toOrgId]: true } : {}),
        ...(fromOrgId ? { [fromOrgId]: true } : {}),
        ...(toUserId ? { [toUserId]: true } : {}),
        ...(fromUserId ? { [fromUserId]: true } : {}),
        toUserId,
        fromUserId,
        fromUserName: createdByName,
        toUserName: newProfile.displayName,
      }

      const convoParticipant: ConvoParticipant = {
        profileId: profile?.id ?? '',
        profileUid: profile?.uid ?? '',
        profileName: profile?.displayName ?? '',
      }

      const convo = await createConversation(
        initState,
        null,
        convoParticipant,
        connectionKey,
      )
      if (convo?.id) {
        convoId = convo.id
      }
    }
  } catch (error) {
    console.error('Error creating conversation', error)
  }
  return convoId
}

export function getConvoTitleByKeyAndProfiles(
  connectionKey: string,
  profiles: Profile[],
): string {
  const connectionUserIds = connectionKey.split('_')
  const uniqueProfiles = Array.from(
    new Map(profiles.map((p) => [p.uid, p])).values(),
  )

  const convoProfileNames = uniqueProfiles
    .filter((p) => connectionUserIds.includes(p.uid ?? ''))
    .map((p) => p.firstName ?? '' + ' ' + p.lastName ?? '')
    .join(', ')

  return convoProfileNames
}

// Temporarily added the following lines to avoid the conflict with mynetwork.tsx
export type { ConvoParticipant }
