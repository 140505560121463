import { useNavigate } from 'react-router-dom'
import {
  addMsgToConversation,
  onConvoMessageUpdate,
  updateConversationById,
  getConversationParticipants,
  upsertConvoParticipant,
  getOtherOrgIdByKeyProps,
  getConvoTitle,
  getConvoTitleByKeyAndProfiles,
  useMyNetworkProfiles,
  useConvoOrgs,
} from '../../../data/services/conversation/conversationService'
import toast from '../../common/Toast'
import { useCallback, useEffect, useRef, useState } from 'react'
import useProfileStore from '../../../data/appState/profileStore'
import {
  ConnectionStatus,
  ConnectionType,
} from '../../../domain/types/Connection'
import { getExploreDataByOrgId } from '../../../data/services/explore/exploreService'
import { ExploreCardData } from '../../../data/services/explore/ExploreCardData'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import {
  displayDateFormatter,
  readableTime,
} from '../../../data/services/explore/converters'
import {
  ArchiveIcons,
  BackArrow,
  DetailsIcons,
  DotsVerticalIcons,
  LucideSend,
  RedDeleteIcons,
  UserGroupIcon,
} from '../../common/utilComponents'
import useConversationsStore from '../../../data/appState/conversationsStore'
import ColorCard from '../ColorCard'
import {
  Conversation,
  ConvoMessage,
  ConvoParticipant,
} from '../../../domain/types/Conversation'
import { Timestamp } from 'firebase/firestore'
import { ConvoOrgDetails } from './ConvoOrgDetails'
import { ChatDetails } from './ChatDetails'
import { Profile } from '../../../domain/types/Profile'

export interface IChatWindowProps {
  conversationId: string
}

export function ChatWindow({ conversationId }: IChatWindowProps) {
  const { profile, orgConnections, removeUnReadConvoId } = useProfileStore()
  const detailsRef = useRef<HTMLDetailsElement | null>(null)
  const { networkProfiles } = useMyNetworkProfiles()
  const { convoOrgs } = useConvoOrgs()
  //const [messages, setMessages] = useState<any>({});

  const { conversations, addConversationMsgs } = useConversationsStore()
  const [convoParticipants, setConvoParticipants] = useState<
    ConvoParticipant[]
  >([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isArchiveModalVisibile, setIsArchiveModalVisibile] = useState(false)

  const handleCloseModal = () => {
    setIsModalVisible(false)
  }
  // Check if firm is real estate and connection is pending - modal not visible
  const handleOpenMessagermodal = () => {
    setIsModalVisible(!isModalVisible)
    handleOptionClick()
  }

  let messages: ConvoMessage[] = []

  if (conversations && conversations[conversationId]) {
    messages = conversations[conversationId] as unknown[] as ConvoMessage[]
  }

  const [newMessage, setNewMessage] = useState<string>('')

  const [orgData, setOrgData] = useState<ExploreCardData | null>(null)

  const navigateTo = useNavigate()

  const currentConversation = orgConnections?.find(
    (convo) => convo.db_ref_id === conversationId,
  )

  let otherOrgId =
    currentConversation?.toOrgId === profile?.organisationId
      ? currentConversation?.fromOrgId
      : currentConversation?.toOrgId

  let otherOrgType =
    currentConversation?.toOrgId === profile?.organisationId
      ? currentConversation?.fromOrgType
      : currentConversation?.toOrgType

  const isDirectConvo =
    currentConversation?.connectionType === ConnectionType.USER_USER

  const connectionStatus: ConnectionStatus = isDirectConvo
    ? ConnectionStatus.ACCEPTED
    : currentConversation?.connectionStatus

  const isPendingState = connectionStatus === ConnectionStatus.PENDING

  const isActive = connectionStatus === ConnectionStatus.ACCEPTED

  const convoRawData = currentConversation

  if ((!otherOrgId || !otherOrgType) && convoRawData?.keyProps && profile) {
    const resOrgId = getOtherOrgIdByKeyProps(
      convoRawData.keyProps,
      profile.organisationId || '',
    )

    if (resOrgId && convoRawData.keyProps[resOrgId]?.type === 'org') {
      otherOrgId = resOrgId
      otherOrgType = convoRawData.keyProps[resOrgId].orgType
    }
  }

  useEffect(() => {
    setOrgData(null)

    if (currentConversation?.connectionType === ConnectionType.USER_USER) {
      const otherProfileId: string | undefined =
        currentConversation.connectionKey?.split('_').find((uid) => {
          return uid !== profile?.uid
        })
      const otherProfile: Profile | undefined = networkProfiles.find(
        (p) => p.uid === otherProfileId,
      )
      if (otherProfile?.organisationId && otherProfile?.organisationName) {
        const { organisationId, orgType } = otherProfile
        getExploreDataByOrgId(organisationId, orgType).then(setOrgData)
      }
    } else {
      if (!otherOrgId || !otherOrgType) return

      getExploreDataByOrgId(otherOrgId, otherOrgType).then(setOrgData)
    }
  }, [
    otherOrgId,
    otherOrgType,
    networkProfiles,
    currentConversation,
    profile?.uid,
  ])

  useEffect(() => {
    getConversationParticipants(conversationId).then(setConvoParticipants)
  }, [conversationId])

  const updateParticipantToConversation = useCallback(() => {
    if (!profile || !conversationId) return

    const { uid, displayName, photoURL, db_ref_id, title } = profile
    if (!uid) return
    const dateVal = new Date()

    const convoParticipant: ConvoParticipant = {
      profileId: db_ref_id,
      profileUid: uid,
      profileName: displayName || 'Participant',
      lastVisited: dateVal,
      updatedOn: dateVal,
      profilePic: photoURL ?? '',
      title: title,
    }

    upsertConvoParticipant(conversationId, convoParticipant)

    removeUnReadConvoId(conversationId)
  }, [conversationId, profile, removeUnReadConvoId])

  useEffect(() => {
    updateParticipantToConversation()
  }, [profile, conversationId, updateParticipantToConversation])

  useEffect(() => {
    if (currentConversation) {
      const { db_ref_id: convoId } = currentConversation

      onConvoMessageUpdate(convoId, (msgs) => {
        addConversationMsgs(convoId, msgs)
      })
    }
  }, [currentConversation, addConversationMsgs])

  const cancelConnectionRequest = () => {
    updateConversationById(currentConversation.db_ref_id, {
      updatedAt: new Date(),
      connectionStatus: ConnectionStatus.CANCELLED,
      CancelledBy: profile?.uid,
      isActive: false,
    })
  }
  const arChiveConvesation = async () => {
    try {
      setIsArchiveModalVisibile(false)
      await updateConversationById(currentConversation.db_ref_id, {
        [`archivedState.${profile?.uid}`]: true,
      })
      handleOptionClick()
      toast.success('Conversation Archived')
    } catch (error) {
      toast.error('Failed to Archive conversation')
    }
  }

  const unArChiveConvesation = async () => {
    try {
      setIsArchiveModalVisibile(false)
      await updateConversationById(currentConversation.db_ref_id, {
        [`archivedState.${profile?.uid}`]: false,
      })
      handleOptionClick()
      toast.success('Conversation Unarchived')
    } catch (error) {
      toast.error('Failed to Unarchive conversation')
    }
  }
  const handleOptionClick = () => {
    if (detailsRef.current) {
      detailsRef.current.removeAttribute('open') // Close the dropdown by removing the 'open' attribute
    }
  }
  const acceptConnectionRequest = async () => {
    await updateConversationById(currentConversation.db_ref_id, {
      connectionStatus: ConnectionStatus.ACCEPTED,
      isActive: true,
      updatedAt: new Date(),
      acceptedByUid: profile?.uid,
      acceptedByName: profile?.displayName,
    })

    await updateParticipantToConversation()
  }

  if (!conversationId) return <div> No conversation selected </div>

  const SendNewMessage = async () => {
    setNewMessage('')
    if (!newMessage.trim() || !currentConversation || !profile?.uid) {
      return
    }

    await addMsgToConversation(conversationId, {
      text: newMessage,
      createdById: profile?.uid,
      createdByName: profile?.displayName,
    })
    if (profile) {
      try {
        await upsertConvoParticipant(conversationId, {
          profileId: profile.id,
          profileUid: profile.uid,
          profileName: profile.displayName ?? '',
          profilePic: profile.photoURL,
          lastMessageSent: new Date(),
          updatedOn: new Date(),
          title: profile.title,
        })
      } catch (e) {
        console.error('Error updating participant', e)
      }
    }
  }

  const PendingActions = () => {
    const isRequestFromMyside =
      currentConversation?.fromOrgId === profile?.organisationId ||
      currentConversation?.createdByUid === profile?.uid

    if (currentConversation?.connectionType === ConnectionType.ORG_ORG) {
      // if the connection is org to org
      if (!isRequestFromMyside) {
        return (
          <div className="chat_actions_btn">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Reject{' '}
            </button>
            <button
              className="btn btn_accept ant-btn-primary"
              onClick={acceptConnectionRequest}
            >
              {' '}
              Accept{' '}
            </button>
          </div>
        )
      } else {
        return (
          <div className="chat_actions_btn">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Cancel Request{' '}
            </button>
          </div>
        )
      }
    } else {
      // if the connection is user to user
      if (isRequestFromMyside) {
        return (
          <div className="chat_actions_btn ">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Cancel Request{' '}
            </button>
          </div>
        )
      } else {
        return (
          <div className="chat_actions_btn ">
            <button
              className="btn ant-btn-default"
              onClick={cancelConnectionRequest}
            >
              {' '}
              Reject{' '}
            </button>
            <button
              className="btn btn_accept ant-btn-primary"
              onClick={acceptConnectionRequest}
            >
              {' '}
              Accept{' '}
            </button>
          </div>
        )
      }
    }
  }
  const getConvoTitleByKeyProps = (
    keyProps: Record<string, { type: string; name: string }>,
    connectionType: ConnectionType,
  ) => {
    // this is to support old data format
    if (!profile) return ''

    try {
      const { organisationId, uid } = profile

      if (connectionType === ConnectionType.USER_USER) {
        for (const key in keyProps) {
          const value = keyProps[key]
          if (key !== uid && value.type === 'user') {
            return value.name
          }
        }
      }

      if (connectionType === ConnectionType.ORG_ORG) {
        for (const key in keyProps) {
          const value = keyProps[key]
          if (key !== organisationId && value.type === 'org') {
            return value.name
          }
        }
      }
    } catch (error) {
      return 'unknown'
    }
  }
  const PendingConvoMessage = () => {
    const isRequestByMe = currentConversation?.createdByUid === profile?.uid

    if (currentConversation?.connectionType === ConnectionType.ORG_ORG) {
      const isRequestFromMyside =
        currentConversation?.fromOrgId === profile?.organisationId

      const orgName = isRequestFromMyside
        ? currentConversation?.toOrgName
        : currentConversation?.fromOrgName

      const createdByName = currentConversation?.createdByName
        ? currentConversation?.createdByName
        : 'Someone'

      const sentOn = currentConversation?.createdOn
        ? displayDateFormatter(
            currentConversation?.createdOn,
            'MMM DD, YYYY h:mm A',
          )
        : ''

      if (isRequestFromMyside)
        return isRequestByMe ? (
          <div className="messageContainer">
            You have Requested to {orgName} on {sentOn}
          </div>
        ) : (
          <div>
            {' '}
            A Request a has been to {orgName} on {sentOn}
          </div>
        )
      else
        return (
          <div>
            {' '}
            {createdByName} from {orgName} Requested a connection to your
            organization on {sentOn}
          </div>
        )
    } else {
      return isRequestByMe ? (
        <div>You have Requested </div>
      ) : (
        <div> You Have got Request </div>
      )
    }
  }

  let convoAvatar = (
    <Avatar
      shape="circle"
      size={36}
      src={<UserOutlined style={{ color: '#44475b' }} />}
    />
  )

  if (currentConversation?.connectionType === ConnectionType.ORG_ORG) {
    currentConversation.title = getConvoTitle(
      currentConversation,
      profile,
      networkProfiles,
      convoOrgs,
    )
  }
  if (currentConversation?.connectionType === ConnectionType.USER_USER) {
    currentConversation.title = getConvoTitleByKeyAndProfiles(
      currentConversation.connectionKey,
      networkProfiles,
    )
  }

  let convoTitle: string | undefined = currentConversation?.title
  const isGroupChat =
    currentConversation?.connectionType === ConnectionType.ORG_ORG
  let subTitle: string | undefined = [
    // 'You',
    ...convoParticipants
      .filter((c) => c.profileUid !== profile?.uid)
      .map((c) => c.profileName),
  ]
    .filter((t) => !!t)
    .join(', ')

  let convoAvatarImgUrl: undefined | string

  const isMultiUsers: boolean =
    !isGroupChat && currentConversation?.connectionKey.split('_').length > 2
  if (!isGroupChat && !isMultiUsers) {
    subTitle = orgData?.title
  }

  if (!convoTitle && currentConversation?.keyProps) {
    convoTitle = getConvoTitleByKeyProps(
      currentConversation.keyProps,
      currentConversation.connectionType,
    )
  }

  if (isMultiUsers && !isGroupChat) {
    subTitle = convoTitle
    convoTitle = 'Group Message'
  }

  if (convoAvatarImgUrl) {
    convoAvatar = <Avatar shape="circle" size={36} src={orgData?.imageUrl} />
  } else {
    convoAvatar = <ColorCard name={convoTitle?.charAt(0) || 'O'} />
  }

  const sortedMessages = [...messages]
    .filter((m: Conversation) => {
      if (currentConversation?.connectionType === ConnectionType.USER_USER) {
        const createdBy = m.createdById
        if (!createdBy) return false
        return currentConversation[createdBy] === true
      }
      return true
    })
    .sort((a: Conversation, b: Conversation) => {
      const aDate: Date | undefined =
        a.createdOn instanceof Timestamp ? a.createdOn.toDate() : a.createdOn

      const bDate: Date | undefined =
        b.createdOn instanceof Timestamp ? b.createdOn.toDate() : b.createdOn

      if (!aDate || !bDate) return 0
      return bDate.getTime() - aDate.getTime()
    })

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      SendNewMessage()
    }
  }
  const MessagesList = (convoMessages: ConvoMessage[]) => {
    return convoMessages.map((message, index) => {
      const isMyMessage = message.createdById === profile?.uid
      return (
        <div
          key={message.id || index}
          className={
            isMyMessage ? 'chat-msg right-msg' : 'chat-msg left-msg  gap-2'
          }
        >
          <div className="convo-avatar"> {convoAvatar} </div>
          <div>
            <div className="chat-text">
              <span>{message.text} </span>
            </div>
            <div className="chat-status">
              <span>{!isMyMessage ? message?.createdByName : ''} </span>
              <span>• </span>
              <span>{readableTime(message?.createdOn)} </span>
            </div>
          </div>
        </div>
      )
    })
  }

  const statusClass =
    currentConversation?.connectionType === ConnectionType.ORG_ORG
      ? currentConversation?.connectionStatus
      : ''

  let isOtherOrgIdDisabled = false
  if (orgData?.orgData?.isDisabled) {
    isOtherOrgIdDisabled = orgData.orgData.isDisabled === true ? true : false
  }

  return (
    <div
      className={`chat_view   ${isOtherOrgIdDisabled === true ? 'isdisabled' : ''}`}
    >
      <div className="chat_header  max-sm:gap-2 max-sm:p-4 bg-[#191B1E] border-[#0C0E12] border-b-[1px] h-[77px] flex justify-between max-sm:justify-start items-center rounded-tr-[8px] max-sm:bg-[#0F1217] max-sm:rounded-none max-sm:border-b-0">
        <div className="back_icon">
          <BackArrow onClick={() => navigateTo(`/chat`)} />
        </div>
        <div
          className="chat_user_content max-sm:flex-1"
          role="button"
          tabIndex={0}
        >
          {/* <div className="convo-avatar"> {convoAvatar} </div> */}
          <div className="chat_title sub_header_title ">
            <h1 className="text-nowrap max-sm:w-[180px] w-[250px] text-ellipsis whitespace-nowrap overflow-hidden">
              {convoTitle}
            </h1>

            <div className="flex items-center gap-1 sub_header_actions">
              {isGroupChat || isMultiUsers ? <UserGroupIcon /> : <> </>}
              <span className="chat_sub_title">{subTitle} </span>
            </div>
          </div>
        </div>

        <div className="p-2 cursor-pointer max-sm:p-0">
          {' '}
          <div className="relative inline-block ">
            <details className="group" ref={detailsRef}>
              <summary className="inline-flex px-3 py-2 cursor-pointer max-sm:p-0">
                <DotsVerticalIcons />
              </summary>

              <div
                className="absolute right-0 z-10 mt-2 w-[200px] origin-top-right  divide-y divide-[#515151]  bg-cool-black border border-[#1E2225] rounded shadow-[0px 4px 4px 0px rgba(0, 0, 0, 0.25);]  ring-black/5 focus:outline-none ring-1 ring-black ring-opacity-5 group-open:block hidden transition ease-out duration-200"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
              >
                <div className="py-1" role="none">
                  <button
                    onClick={handleOpenMessagermodal}
                    className="flex items-center gap-2 px-2 py-2 text-[16px] font-medium text-white  hover:text-white"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-0"
                  >
                    <DetailsIcons /> Details
                  </button>
                  <button
                    onClick={() => {
                      setIsArchiveModalVisibile(true)
                    }}
                    className="flex items-center gap-2 px-2 py-2 text-[16px] font-medium text-white hover:text-white"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-1"
                  >
                    {' '}
                    <ArchiveIcons />
                    {profile?.uid && currentConversation ? (
                      <>
                        {currentConversation.archivedState &&
                        currentConversation.archivedState[profile?.uid]
                          ? 'Unarchive'
                          : 'Archive'}
                      </>
                    ) : (
                      <></>
                    )}
                  </button>
                </div>
                <div className="">
                  <button
                    className="flex items-center gap-2 px-2 py-2 text-[16px] font-medium text-[#F04438] hover:text-[#F04438]"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-2"
                  >
                    {' '}
                    <RedDeleteIcons /> Delete
                  </button>
                </div>
              </div>
            </details>
          </div>
        </div>
      </div>
      <div
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        className={`fixed   right-4 bottom-4 top-28 z-50 max-[600px]:top-2 max-[600px]:right-0 max-[600px]:left-0 max-[600px]:bottom-0 flex items-center justify-center bg-opacity-50 transition-transform   ${isModalVisible ? '  md:translate-x-0 max-[600px]:translate-y-0' : ' md:translate-x-[110%] max-[600px]:translate-y-[110%]'}`}
      >
        <div className="">
          <div className="w-[343px] max-[600px]:w-dvw  max-[600px]:rounded-t-lg max-[600px]:border-0  flex flex-col h-screen max-h-[85dvh] max-[600px]:max-h-[100vh] bg-[#191B1E] border border-[#515151] shadow-[0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);]">
            <div className="flex items-center justify-between px-6 pt-6 pb-2 mb-6 ">
              <div className="text-xl font-semibold text-white max-sm:text-[16px] max-sm:text-center max-sm:font-medium max-sm:m-auto ">
                Message Details
              </div>
              <button
                className="text-2xl text-[#a1a1a1] hover:text-white"
                onClick={handleCloseModal}
              >
                &times;
              </button>
            </div>

            <ChatDetails
              orgData={orgData}
              convoParticipants={convoParticipants}
              isMultiUserChat={isMultiUsers}
              networkProfiles={networkProfiles}
              conversation={currentConversation}
            ></ChatDetails>

            <div className="flex items-center justify-between py-4 px-6 max-[600px]:p-4 mt-auto border-t bg-[#0F1217] border-[#A1A1A1]">
              <button className="text-[#F04438]  items-center flex gap-2">
                <RedDeleteIcons /> Delete
              </button>
              <button
                className="px-4 py-2 text-[#E4E4E4] border border-[#ffffff] rounded-md hover:bg-gray-600"
                onClick={() => {
                  setIsArchiveModalVisibile(true)
                }}
              >
                {profile?.uid && currentConversation ? (
                  <>
                    {currentConversation.archivedState &&
                    currentConversation.archivedState[profile?.uid]
                      ? 'Unarchive'
                      : 'Archive'}
                  </>
                ) : (
                  <></>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`relative z-50 ${isArchiveModalVisibile ? 'flex' : 'hidden'}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="fixed inset-0 bg-[#191B1E] bg-opacity-50 flex items-center max-[600px]:items-end justify-center z-50">
          <div className=" p-6 relative bg-[#191B1E] border border-[#515151] shadow-[0px 4px 4px 0px rgba(0, 0, 0, 0.25);] rounded max-[600px]:rounded-t-lg max-[600px]:border-0  max-w-[500px] w-full">
            <div className="flex items-center justify-between mb-4">
              <div className="text-base font-semibold text-white max-sm:m-auto">
                {profile?.uid && currentConversation ? (
                  <>
                    {currentConversation.archivedState &&
                    currentConversation.archivedState[profile?.uid]
                      ? 'Unarchive'
                      : 'Archive'}
                  </>
                ) : (
                  <></>
                )}{' '}
                Message?
              </div>

              <button
                onClick={() => setIsArchiveModalVisibile(false)}
                className="absolute top-6 right-6 text-[#a1a1a1] hover:text-gray-700 focus:outline-none"
                aria-label="Close Modal"
              >
                ✕
              </button>
            </div>

            <p className="text-[#C6C6C6] text-base mb-6">
              This hides the message and mutes notifications, retrievable by
              filtering your messages or requests.
            </p>

            <div className="flex justify-center space-x-4">
              <button
                onClick={() => setIsArchiveModalVisibile(false)}
                className="px-4 py-2 w-1/2 bg-[#191B1E] text-white border border-white rounded hover:bg-gray-300 focus:outline-none max-sm:hidden"
              >
                Back
              </button>
              <button
                onClick={() => {
                  if (
                    profile?.uid &&
                    currentConversation.archivedState &&
                    currentConversation.archivedState[profile?.uid]
                  ) {
                    unArChiveConvesation()
                  } else {
                    arChiveConvesation()
                  }
                }}
                className="w-1/2 px-4 py-2 font-semibold rounded max-sm:w-full max-sm:!m-0 bg-yellow-green text-cool-black hover:bg-yellow-green focus:outline-none"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="request_div bg-[#0F1113] rounded-br-[8px]">
        {
          // SHOW PENDING Content or ACTIONS ONLY IF THE CONNECTION STATUS IS PENDING
          !isPendingState ? null : (
            <div className="request_section slim_scrollbar">
              <ConvoOrgDetails orgData={orgData}> </ConvoOrgDetails>
              <h3 className="pending_msg"> {PendingConvoMessage()}</h3>
              {PendingActions()}
            </div>
          )
        }

        <div className={'chat_window ' + statusClass}>
          <div className="chating_screen slim_scrollbar">
            {MessagesList(sortedMessages)}
          </div>

          {!isActive ? null : (
            <div className="chat-input-div rounded-br-[8px] bg-[#0F1113]">
              {/* <Iconsax/> */}
              <textarea
                className="text-input bg-light-black h-11 placeholder:#a1a1a1"
                placeholder="Type a message"
                value={newMessage}
                onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setNewMessage(e.target.value)
                }}
              />
              <div className="lucide_send_icon">
                <LucideSend onClick={SendNewMessage} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
