import React, { useEffect } from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { InvestmentFirm } from '../../domain/types/Investor'
import { getInvestmentFirmById } from '../../data/services/investor/investorService'

import { Button } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView'
import { BackArrow, EditIcon } from '../common/utilComponents'

export default function InvestorFirmPage() {
  const { id: investorFirmId } = useParams()

  const { profile } = useProfileStore()
  const orgId = investorFirmId || profile?.organisationId
  const navigateTo = useNavigate()

  const [investmentFirm, setInvestmentFirm] =
    React.useState<InvestmentFirm | null>(null)

  useEffect(() => {
    if (orgId)
      getInvestmentFirmById(orgId)
        .then((data) => {
          setInvestmentFirm(data)
        })
        .catch((err) => console.error(err))
  }, [orgId])

  return (
    <div className="body_layout">
      <div className="sub_header">
        <div
          className="back_icon"
          role="button"
          tabIndex={0}
          onClick={() => navigateTo(-1)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateTo(-1)
            }
          }}
        >
          <BackArrow />
          <h2>Back</h2>
        </div>
        <ul className="sub_header_actions">
          {profile?.id && investmentFirm?.createdById ? (
            profile?.id.toLocaleLowerCase() ===
            investmentFirm?.createdById.toLocaleLowerCase() ? (
              <Button
                type="link"
                className="p-0 bg-transparent border-none shadow-none text-inherit hover:underline !gap-1"
                onClick={() =>
                  navigateTo(`/investor/${investmentFirm?.id}/edit`)
                }
              >
                <EditIcon /> <h2>Edit</h2>
              </Button>
            ) : (
              <li>
                <Button type="primary" disabled>
                  Connect
                </Button>
              </li>
            )
          ) : null}
        </ul>
        {/* )} */}
      </div>

      <div className="wvp-p-flex-d">
        <div className="left-top-position">
          <div className="left-info"></div>

          <div className="deck-slideshow">
            <div className="re-company-deck">
              {investmentFirm ? (
                <ExpandedInvestmentFirmView
                  investmentFirm={investmentFirm}
                  showSocialIcons={true}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
