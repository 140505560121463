import * as React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useProfileStore from '../../data/appState/profileStore'
import { useEffect } from 'react'
import { RealEstateSponsor, TeamMember } from '../../domain/types/RealEstate'

import { getReSponsorById } from '../../data/services/realEstate/realEstateService'
import { Button, Space, Tag } from 'antd'

import SocialIcons from '../components/SocialIconsView'
import { ShimmerSocialPost } from 'react-shimmer-effects'

import { realestateSiteProperties } from '../../data/services/common/siteDataService'
import { getlabelsByValues } from '../../data/services/explore/converters'
import { BLANK_IMG, DEFAULT_IMG } from '../common/asset_paths'
import { replaceWithBr } from '../common/utils'
import { BackArrow, EditIcon } from '../common/utilComponents'

export default function RealEstatePage() {
  const { id: sponsorId } = useParams()
  const { profile } = useProfileStore()
  const navigateTo = useNavigate()

  const [realEstate, setRealEstate] = React.useState<RealEstateSponsor | null>(
    null,
  )

  const orgId = sponsorId || profile?.organisationId
  const { assets_under_management, property_sector } =
    realestateSiteProperties ?? {}

  useEffect(() => {
    if (orgId)
      getReSponsorById(orgId)
        .then((data) => {
          setRealEstate(data)
        })
        .catch((err) => console.error(err))
  }, [orgId])
  const getUrl = (url) => {
    if (url && (url?.indexOf('http') === -1 || url.indexOf('www.') === 0)) {
      return `https://${url}`
    } else {
      return url
    }
  }
  if (!profile || !realEstate) return <ShimmerSocialPost type="both" />

  return (
    <div className="body_layout">
      <div className="sub_header">
        <div
          className="back_icon"
          onClick={() => navigateTo(-1)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') navigateTo(-1)
          }}
          aria-label="Go back"
        >
          <BackArrow />
          <h2>Back</h2>
        </div>
        <ul className="sub_header_actions">
          {sponsorId ? (
            <></>
          ) : (
            <Button
              onClick={() => navigateTo(realEstate.id + '/edit')}
              className="edit-button !gap-1"
              type="text"
            >
              <EditIcon /> <h2>Edit</h2>
            </Button>
          )}
        </ul>
        {/* )} */}
      </div>

      <div className="wvp-p-flex-d">
        <h2> {realEstate?.name}</h2>
        <div className="card">
          <div className="header-image">
            <img
              alt=""
              src={realEstate?.logoUri ? realEstate?.logoUri : DEFAULT_IMG}
            />
          </div>
          <div className="card_left_body">
            <div className=" list_group">
              <div className="text-group">
                <p>Assets Under Management</p>
                {!realEstate?.assetsUnderManagement ? (
                  <></>
                ) : (
                  getlabelsByValues(
                    [realEstate?.assetsUnderManagement],
                    assets_under_management.options,
                  ).map((label: string, index: number) => (
                    <span key={`${label}-${index}`}>{label}</span>
                  ))
                )}
              </div>

              <div className="text-group">
                <p>Number of Deals Completed</p>
                <span>{realEstate?.numberOfDealsCompleted}</span>
              </div>

              <div className="text-group">
                <p>Years in Industry</p>
                <span>{realEstate?.yearsInIndustry}</span>
              </div>
            </div>
          </div>
        </div>

        <h3 className="Secondary-textcolor soft_green">
          Sectors you have had deals in
        </h3>
        <Space size={8} wrap className="tag_lists">
          {getlabelsByValues(realEstate?.sectors, property_sector?.options).map(
            (label: string, index: number) => (
              <Tag key={index}>
                <div className="tag_text">{label}</div>
              </Tag>
            ),
          )}
        </Space>

        <h3 className="Secondary-textcolor soft_green">About</h3>
        <div>
          {realEstate?.about ? (
            <p
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: replaceWithBr(realEstate?.about),
              }}
            />
          ) : (
            <></>
          )}
        </div>

        <h3 className="Secondary-textcolor soft_green">Team</h3>

        <div className="team_view">
          {realEstate?.teamMembers && realEstate?.teamMembers.length !== 0 ? (
            realEstate?.teamMembers?.map((tm: TeamMember, index: number) => {
              return (
                <a key={index} href={getUrl(tm.socialLink)} target="blank">
                  <div className="team_card" key={`${tm.photoUrl}-${index}`}>
                    <div className="team_profile_img">
                      {tm.photoUrl ? (
                        <img alt="" src={tm.photoUrl} />
                      ) : (
                        <img alt="" src={BLANK_IMG} />
                      )}
                    </div>

                    <div className="teammember-list">
                      <h2>{tm.displayName}</h2>

                      <h3 className="">{tm?.title}</h3>
                    </div>
                  </div>
                </a>
              )
            })
          ) : (
            <></>
          )}
        </div>
        <h3 className="Secondary-textcolor soft_green">Firm</h3>
        <div className="width_div">
          <div className="list_group">
            <div className="text-group">
              {' '}
              {realEstate?.city ? (
                <>
                  {' '}
                  <p>City</p> <span>{realEstate?.city}</span>{' '}
                </>
              ) : (
                <></>
              )}{' '}
            </div>
            <div className="text-group">
              {realEstate?.state ? (
                <>
                  {' '}
                  <p>State</p>
                  <span> {realEstate?.state}</span>
                </>
              ) : (
                <></>
              )}{' '}
            </div>
            <div className="text-group">
              {realEstate?.country ? (
                <>
                  {' '}
                  <p>Country</p>
                  <span> {realEstate?.country}</span>{' '}
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="text-group">
              <p>Website</p>

              <Link target="_blank" to={getUrl(realEstate?.websiteUri)}>
                <span>{realEstate?.websiteUri}</span>
              </Link>
            </div>
          </div>
        </div>

        <h3 className="Secondary-textcolor">
          Social <span>Links</span>{' '}
        </h3>
        <div className="social_icons">
          {realEstate &&
          realEstate?.socialLinks &&
          realEstate?.socialLinks?.length !== 0 ? (
            <SocialIcons iconsList={realEstate?.socialLinks}></SocialIcons>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
