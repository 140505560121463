import React from 'react'
import Thumbnail from '../Thumbnail/Thumbnail'
import { getReadableFundingRound } from '../../utils/formatters'
import { SocialIcon } from 'react-social-icons'
interface CompanyMetaDataProps {
  logoUrl?: string
  name: string
  fundingRound?: string
  offeringType?: string
  seekingAmount?: string
  socialLinks?: string[]
  variant?: 'user' | 'company' | 'investor'
  about?: string
  location?: string
  position?: string
}
export const CompanyMetaData: React.FC<CompanyMetaDataProps> = ({
  logoUrl,
  name,
  fundingRound,
  offeringType,
  seekingAmount,
  socialLinks,
  variant = 'company',
  about,
  location,
  position,
}) => {
  const readableFundingRound =
    fundingRound && getReadableFundingRound(fundingRound)
  const isMobile = window.innerWidth <= 768

  const renderSocialLinks = () => {
    return (
      <div className="flex space-x-4">
        {socialLinks?.map((link, index) => (
          <SocialIcon
            key={index}
            url={link}
            bgColor={'#30353b'}
            fgColor="white"
            style={{ width: 40, height: 40 }}
            target="_blank"
            rel="noopener noreferrer"
          />
        ))}
      </div>
    )
  }

  return !isMobile ? (
    <div className="grid grid-cols-10 mb-8">
      <div className="col-span-1 flex items-center">
        <div className="flex-1">
          <Thumbnail imageUrl={logoUrl} title={name} circle showFullName />
        </div>
      </div>
      <div className="col-span-7 ml-8">
        <h2 className="text-pure-white text-2xl mb-2">{name}</h2>
        {variant === 'company' && (
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12">
              <p className="text-pure-white">
                <span className="text-gray">Round:&nbsp;</span>
                {readableFundingRound &&
                  readableFundingRound.charAt(0) +
                    readableFundingRound.slice(1).toLowerCase()}
                &nbsp;|&nbsp;
                <span className="text-gray">Offering: &nbsp;</span>
                {offeringType}
              </p>
            </div>
            <div className="col-span-12">
              <p className="text-pure-white">
                <span className="text-gray">Seeking: &nbsp;</span>
                {seekingAmount}
              </p>
            </div>
          </div>
        )}
        {variant === 'user' && (
          <p
            className="text-pure-white"
            dangerouslySetInnerHTML={about ? { __html: about } : undefined}
          />
        )}
        <p className="text-gray">{location}</p>
      </div>
      <div className="col-span-2 flex justify-end">
        <div className="flex-col">
          <p className="text-white mb-2">Social Links</p>
          <div className="flex-row">{renderSocialLinks()}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className="text-center">
      <div className="relative w-full">
        {variant === 'user' && (
          <div className="absolute top-0 right-0">{renderSocialLinks()}</div>
        )}
        <div className="flex justify-center flex-col items-center">
          <Thumbnail imageUrl={logoUrl} title={name} circle />
          <h2 className="text-pure-white text-2xl mt-2 w-full text-center items-center">
            {name}
          </h2>
        </div>
      </div>
      {variant === 'company' && (
        <>
          <p className="text-pure-white">
            <span className="text-gray">Funding Round: &nbsp;</span>
            {readableFundingRound &&
              readableFundingRound.charAt(0) +
                readableFundingRound.slice(1).toLowerCase()}
          </p>
          <p className="text-pure-white">
            <span className="text-gray">Offering: &nbsp;</span>
            {offeringType}
          </p>
          <div className="flex-col">
            <span className="text-gray">Seeking</span>
            <p className="text-pure-white">{seekingAmount}</p>
          </div>
        </>
      )}
      {variant === 'user' && (
        <>
          <p className="text-gray font-semibold text-xl">{position}</p>
          <p
            className="text-pure-white"
            dangerouslySetInnerHTML={about ? { __html: about } : undefined}
          />
        </>
      )}
    </div>
  )
}
