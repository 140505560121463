import * as React from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from 'antd'
import ColorCard from '../ColorCard'
import {
  Conversation,
  ConvoParticipant,
} from '../../../domain/types/Conversation'
import { ExploreCardData } from '../../../data/services/explore/ExploreCardData'
import { Profile } from '../../../domain/types/Profile'

export interface IChatDetailsProps {
  orgData: ExploreCardData | null
  convoParticipants: ConvoParticipant[]
  isMultiUserChat?: boolean
  networkProfiles: Profile[]
  conversation: Conversation
}

// using it only for company

export function ChatDetails({
  orgData,
  convoParticipants,
  isMultiUserChat,
  networkProfiles,
  conversation,
}: IChatDetailsProps) {
  // using it only for company
  const industries = orgData?.detailsList?.find(
    (item) => item.label === 'Industries',
  )?.value

  if (conversation?.connectionType === 'USER_USER') {
    let userIds: string[] = []
    if (conversation.connectionKey) {
      userIds = conversation.connectionKey.split('_')
      const convoProfiles = networkProfiles.filter(
        (profile) => profile.uid && userIds.includes(profile.uid),
      )

      if (convoProfiles.length > 0) {
        convoParticipants = convoProfiles.map((profile) => {
          return {
            profileId: profile.id,
            profileName: profile.displayName,
            profilePic: profile.photoURL,
            title: profile.title,
            profileUid: profile.uid,
          } as ConvoParticipant
        })
      }
    }
  }

  const detailItemValue = (value: string | number | string[] | number[]) => {
    if (typeof value == 'object' && value.length) {
      return value.map((item: string | number, index) => {
        if (index > 2) return null

        return (
          <span
            className="px-2 py-1 text-sm text-white bg-[#30353B] rounded"
            key={index}
          >
            {item}
          </span>
        )
      })
    } else {
      return value
    }
  }

  return (
    <>
      <h1 className="px-6 text-lg font-medium text-left text-white">
        {isMultiUserChat ? 'Group Message' : orgData?.title}
      </h1>

      <div className="w-full overflow-auto max-h-[85dvh] max-[600px]:max-h-full  scrollbar-[#a1a1a1] max-w-md p-6 bg-[#191B1E]">
        <div className={isMultiUserChat ? 'hidden' : 'block'}>
          {orgData?.orgType?.toLowerCase() === 'investor' ? (
            <>
              <div>
                {' '}
                <h2 className="mb-4  text-sm font-medium text-[#a1a1a1]">
                  Investor Details
                </h2>
                <div className="pl-2">
                  {orgData?.detailsList?.map((dlItem, index) => {
                    if (
                      !dlItem?.label ||
                      !dlItem?.value ||
                      (Array.isArray(dlItem?.value) &&
                        dlItem.value.length === 0)
                    )
                      return null
                    const extraCount = Array.isArray(dlItem?.value)
                      ? dlItem?.value.length - 3
                      : 0
                    return (
                      <div className="mb-4" key={index}>
                        <h3
                          key={`id-${dlItem.label}`}
                          className="mb-2 text-sm font-semibold text-[#a1a1a1]"
                        >
                          {dlItem?.label}
                        </h3>
                        <div className="flex flex-wrap gap-2">
                          {dlItem?.value ? detailItemValue(dlItem?.value) : ''}
                          {extraCount > 0 ? (
                            <span className="py-1 text-sm text-white ">
                              +{extraCount}
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          ) : (
            <>
              {' '}
              {orgData?.orgType?.toLowerCase() === 'company' ? (
                <div>
                  {' '}
                  <h2 className="mb-4  text-sm font-medium text-[#a1a1a1]">
                    Company Details
                  </h2>
                  <div className="pl-2">
                    {/* {JSON.stringify(orgData?.detailsList)} */}

                    <div className="grid grid-cols-2 gap-4 mb-4">
                      {orgData?.detailsList
                        .filter((item) => item.label !== 'Industries')
                        .map((item, index) => {
                          if (
                            !item?.label ||
                            !item?.value ||
                            (Array.isArray(item?.value) &&
                              item.value.length === 0)
                          )
                            return null
                          return (
                            <div key={index} className="text-left grid-item">
                              <div className="text-gray font-semibold text-[14px]">
                                {item.label}
                              </div>
                              <div className="font-medium text-[14px] text-white capitalize">
                                {Array.isArray(item.value)
                                  ? item.value[0]
                                  : item.value}
                              </div>
                              {item.label === 'Structure' &&
                              Array.isArray(item.value) &&
                              item?.value?.length > 1 ? (
                                <span className="py-1 text-sm text-white ">
                                  +{item?.value?.length - 1}
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          )
                        })}
                    </div>
                    {!industries ||
                    (Array.isArray(industries) && industries.length === 0) ? (
                      <></>
                    ) : (
                      <div className="flex flex-col items-start gap-1 self-stretch w-full relative flex-none h-[136px] max-h-fit">
                        <div className="relative text-sm not-italic font-semibold leading-5 tracking-normal text-gray-400 w-fit font-work-sans whitespace-nowrap text-gray undefined">
                          Industry Focus
                        </div>
                        <div className="relative flex flex-wrap items-center w-full gap-2">
                          {orgData?.detailsList?.length &&
                          Array.isArray(industries)
                            ? industries.map((industry, index) => {
                                if (index > 2) return null
                                return (
                                  <div
                                    key={index}
                                    className="relative inline-flex items-center justify-center flex-none px-2 py-1 rounded bg-light-black"
                                  >
                                    <div className="relative w-fit font-['Work_Sans'] font-medium text-white text-[14px] tracking-normal leading-[24px] max-w-[240px] overflow-hidden whitespace-nowrap text-ellipsis">
                                      {industry}
                                    </div>
                                  </div>
                                )
                              })
                            : null}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
          {/* realestate view */}
          {orgData?.orgType?.toLowerCase() === 'realestate' ? (
            <></>
          ) : (
            <Link
              to={`/${orgData?.orgType?.toLowerCase()}/Messenger/${orgData?.id}`}
            >
              <p className="mt-4 text-sm font-medium text-left text-yellow-green">
                View Profile
              </p>
            </Link>
          )}
        </div>
        <div className="members">
          <h3 className="mb-2 text-sm text-[#a1a1a1] font-medium">People</h3>
          <div className="members_list">
            {convoParticipants && convoParticipants?.length > 0 ? (
              convoParticipants.map((member, index: number) => {
                return (
                  <div className="member" key={index}>
                    {member?.profilePic ? (
                      <Avatar
                        shape="circle"
                        size={36}
                        src={member?.profilePic}
                      />
                    ) : (
                      <div className="convo-avatar">
                        <ColorCard
                          name={member?.profileName?.charAt(0) || 'O'}
                        />
                      </div>
                    )}
                    <div className="member-info">
                      <span className="member-name">{member?.profileName}</span>
                      <span className="member-role">{member?.title}</span>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="member-role">NO MEMBERS</div>
            )}
          </div>
        </div>
      </div>
      <div className="px-6 my-4 ">
        <h3 className="mb-2 text-sm font-medium text-[#a1a1a1]">Settings</h3>
        <div className="flex items-center justify-between">
          <span className="text-sm text-white">Mute</span>
          <label
            htmlFor="mute-toggle"
            className="relative inline-flex items-center cursor-pointer"
          >
            <input
              id="mute-toggle"
              type="checkbox"
              value=""
              className="sr-only peer"
              aria-labelledby="mute-toggle-label"
            />
            <div
              id="mute-toggle-label"
              className="w-[52px] h-[30px] bg-[#a1a1a1] filter peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-yellow-green rounded-full peer peer-checked:after:translate-x-5 peer-checked:bg-yellow-green after:content-[''] after:absolute after:top-[0.3rem] after:left-1 after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all"
            ></div>
          </label>
        </div>
        <p className="text-xs text-[#a1a1a1]">
          Stops notifications for this message.
        </p>
      </div>
    </>
  )
}
