import * as React from 'react'
import {
  MessageChatIcons,
  MessageNotificationIcons,
  EditIcons,
} from '../../common/utilComponents'
import { ChatFilters } from './ChatFilters'
import { ChatList } from './ChatList'
import { useNavigate, useParams } from 'react-router-dom'

export interface IChatTabsProps {
  unreadCount: number
  pendingCount: number
}

export function ChatTabs({ unreadCount, pendingCount }: IChatTabsProps) {
  const { type: paramfilterType } = useParams()
  const navigateTo = useNavigate()
  return (
    <div className="flex h-full">
      <div className="flex max-md:!hidden flex-col  h-full bg-[#191B1E] border-r border-[#0F1113]  pt-4 rounded-tl-[8px] rounded-bl-[8px] ">
        <button
          className={`flex relative w-[190px]  items-center gap-1 px-4 py-2 cursor-pointer ${paramfilterType === 'primary' ? 'msgactive' : ''}`}
          onClick={() => {
            navigateTo('/chat/type/primary')
          }}
        >
          {' '}
          <MessageChatIcons className="p-2" />{' '}
          <span
            className={`text-[18px] font-semibold ${paramfilterType === 'primary' ? 'text-white' : 'text-[#727272]'}`}
          >
            Messages
            {unreadCount > 0 && (
              <span className="absolute top-5 right-5 rounded-full bg-white text-center flex items-center font-bold justify-center text-cool-black text-[10px] w-4 h-4">
                {unreadCount ?? ''}
              </span>
            )}
          </span>
        </button>
        <button
          className={`relative flex items-center  w-[190px] gap-1 px-4 py-2 cursor-pointer ${paramfilterType === 'requests' ? 'msgactive' : ''}`}
          onClick={() => {
            navigateTo('/chat/type/requests')
          }}
        >
          {' '}
          <MessageNotificationIcons className="p-2" />{' '}
          <span
            className={`text-[18px] font-semibold  ${paramfilterType === 'requests' ? 'text-white' : 'text-[#727272]'}`}
          >
            Requests
          </span>{' '}
          {pendingCount > 0 && (
            <span className="absolute top-5 right-5 rounded-full bg-white text-center flex items-center justify-center font-bold text-cool-black text-[10px] w-4 h-4">
              {pendingCount ?? ''}
            </span>
          )}
        </button>
      </div>
      <div className="messagesContainer bg-[#0F1113] h-full max-md:w-full w-full border-r border-[#121316]">
        <div className="flex items-center   md:!hidden border-b border-[#515151]">
          <button
            className={`flex items-center justify-center relative gap-1 px-4 py-2 w-1/2 cursor-pointer   ${paramfilterType === 'primary' ? 'msgactive border-b border-[#a1a1a1]' : ''}`}
            onClick={() => {
              navigateTo('/chat/type/primary')
            }}
          >
            {' '}
            <MessageChatIcons />{' '}
            <span
              className={`text-[18px] font-semibold ${paramfilterType === 'primary' ? 'text-white' : 'text-[#727272]'}`}
            >
              Messages
            </span>
            {unreadCount > 0 && (
              <span className="absolute top-[14px] right-[18px] rounded-full bg-white text-center font-bold flex items-center justify-center text-cool-black text-[10px] w-4 h-4">
                {unreadCount ?? ''}
              </span>
            )}
          </button>
          <button
            className={`relative flex items-center justify-center w-1/2 gap-1 px-4 py-2 cursor-pointer ${paramfilterType === 'requests' ? 'msgactive' : ''}`}
            onClick={() => {
              navigateTo('/chat/type/requests')
            }}
          >
            {' '}
            <MessageNotificationIcons />{' '}
            <span
              className={`text-[18px] font-semibold  ${paramfilterType === 'requests' ? 'text-white' : 'text-[#727272]'}`}
            >
              Requests
            </span>{' '}
            {pendingCount > 0 && (
              <span className="absolute top-[14px] right-[18px] rounded-full bg-white text-center flex items-center font-bold justify-center text-cool-black text-[10px] w-4 h-4">
                {pendingCount ?? ''}
              </span>
            )}
          </button>
        </div>
        <div className="flex gap-4 search_header bg-[#191B1E] max-sm:sticky top-[84.8px] z-10 max-sm:bg-[#0F1217]  max-sm:border-b max-sm:border-[#0C0E12]">
          <ChatFilters />
          <button
            onClick={() => {
              navigateTo('/chat/type/primary/new')
            }}
            className="p-2 cursor-pointer"
          >
            {' '}
            <EditIcons />
          </button>
        </div>

        <ChatList></ChatList>
      </div>
    </div>
  )
}
