import { useEffect, useState } from 'react'
import { Stepper } from '../../components/Stepper/Stepper'
import { Button, Form, Input, Select } from 'antd'
import { OrgType } from '../../../domain/types/Profile'
import { Invite } from '../../../domain/types/Invite'
import useProfileStore from '../../../data/appState/profileStore'
import toast from '../../common/Toast'
import {
  createInvite,
  getInviteByEmail,
} from '../../../data/services/Invites/InviteService'
import { useNavigate } from 'react-router-dom'
import { BackArrow } from '../../common/utilComponents'

export type AdminInviteAccount = {
  orgName: string
  website: string
}

export type AdminInviteUser = {
  firstName: string
  lastName: string
  title: string
  email: string
}

type AccountCreateProps = {
  defaultAccountType?: OrgType
  onCreateSuccess?: () => void
}
function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}
export const AccountCreate = ({
  defaultAccountType,
  onCreateSuccess,
}: AccountCreateProps) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [accountType, setAccountType] = useState<OrgType>(
    defaultAccountType || 'COMPANY',
  )
  const navigateTo = useNavigate()
  const { profile } = useProfileStore()
  const [accountInfo, setAccountInfo] = useState<AdminInviteAccount>({
    orgName: '',
    website: '',
  })

  const steps = [
    {
      title: 'Account type',
      subtitle: 'Select account type',
    },
    {
      title: 'Account information',
      subtitle: 'Fill out Account information',
    },
    {
      title: 'Account owner',
      subtitle: 'Account owner information',
    },
  ]

  const createUserInvite = async (data: AdminInviteUser) => {
    const { email } = data
    if (!email) return
    //Duplicated code .. Need to refactor
    // validate email
    if (!validateEmail(email)) {
      toast.error('Invalid email', 'Please enter a valid email address')
      return
    }

    const res = await getInviteByEmail(email)
    // let res = await getUserProfileByEmail(email);
    if (res && res.length !== 0) {
      toast.error(
        `Email ${email} already exists.`,
        'Please try with another email',
      )
      return
    }

    const invite = {
      createToEmail: data.email,
      status: 'pending',
      createdOn: new Date(),
      updatedOn: new Date(),
      createdById: profile?.uid,
      createToOrgType: accountType,

      createdByName: profile?.displayName ?? '',
      createdByEmail: profile?.email ?? '',
      createdByOrgId: profile?.organisationId ?? '',
      createdByOrgName: profile?.organisationName ?? '',
      createdByOrgType: profile?.orgType,

      accountInfo: accountInfo,
      userInfo: data,
    } as Invite

    createInvite(invite)
      .then(() => {
        toast.success('Invite sent', 'The invite has been sent successfully')
        if (onCreateSuccess) onCreateSuccess()
      })
      .catch(() => {
        toast.error('Error', 'An error occurred while sending the invite')
      })
    navigateTo(`/admin/accounts/pending`)
  }

  //need to remove this wrapper function
  const handleStepChange = async (nextStep: number) => setActiveStep(nextStep)

  const SelectAccountType = () => {
    return (
      <div className="flex flex-col justify-center">
        <div className="w-3/4 mt-6  w-full">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="accountType"
          >
            Account Type
          </label>
          <Select
            className="w-[300px]"
            placeholder="Select account type"
            value={accountType}
            onChange={(value) => setAccountType(value)}
            options={[
              { value: 'COMPANY', label: 'Company' },
              { value: 'INVESTOR', label: 'Investor' },
              { value: 'REALESTATE', label: 'Real Estate' },
            ]}
          />
        </div>
        <div className="mt-6">
          <Button
            type="primary"
            className="w-[300px]"
            onClick={() => handleStepChange(1)}
          >
            Continue
          </Button>
        </div>
      </div>
    )
  }

  const CreateAccountForm = () => {
    const [CreateAccountForm] = Form.useForm()

    useEffect(() => {
      if (activeStep === 1) {
        CreateAccountForm.setFieldsValue(accountInfo)
      }
    }, [CreateAccountForm])

    const validateURL = (_, value) => {
      const urlPattern =
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/
      if (!value) {
        return Promise.reject(new Error('Website URL is required'))
      }
      if (urlPattern.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Please enter a valid website URL!'))
    }

    const onFinishAccountForm = async (values) => {
      setAccountInfo(values)
      setActiveStep(2)
    }
    return (
      <div className="w-3/4">
        <Form
          form={CreateAccountForm}
          initialValues={accountInfo}
          layout="vertical"
          onFinish={onFinishAccountForm}
        >
          <div className="grid grid-cols-2 gap-4 mt-6">
            <Form.Item
              name="orgName"
              label="Organization Name"
              rules={[
                { required: true, message: 'Organization Name is required' },
              ]}
            >
              <Input
                placeholder="Enter organization name"
                autoComplete="false"
              />
            </Form.Item>
            <Form.Item
              name="website"
              label="Website"
              rules={[{ validator: validateURL }]}
            >
              <Input placeholder="Enter company website" />
            </Form.Item>
            <Button
              className="w-[300px] ml-6 mt-4"
              onClick={() => setActiveStep(0)}
            >
              Back
            </Button>
            <Button
              type="primary"
              className="w-[300px]  mt-4"
              htmlType="submit"
            >
              Continue
            </Button>
          </div>
        </Form>
      </div>
    )
  }

  const CreateUserForm = () => {
    const [CreateUserForm] = Form.useForm()

    const onFinishUserForm = async (values) => {
      createUserInvite(values)
    }
    return (
      <div className="w-full">
        <Form
          form={CreateUserForm}
          layout="vertical"
          onFinish={onFinishUserForm}
          // initialValues={accountInfo}
        >
          <div className="grid grid-cols-2 gap-4 mt-6 ">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: 'First Name is required' }]}
            >
              <Input placeholder="Enter firstName" autoComplete="false" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: 'Last Name is required' }]}
            >
              <Input placeholder="Enter lastName" autoComplete="false" />
            </Form.Item>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Title is required' }]}
            >
              <Input placeholder="Enter title" autoComplete="false" />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Please enter a valid email!' },
              ]}
            >
              <Input placeholder="Enter email" autoComplete="false" />
            </Form.Item>
          </div>
          <div className="mt-3">
            <Button className="w-[200px] m-3" onClick={() => setActiveStep(1)}>
              Back
            </Button>
            <Button type="primary" className="w-[200px] m-3" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    )
  }

  const actionView = (activeStep: number) => {
    let view = <></>

    switch (activeStep) {
      case 0:
        view = <SelectAccountType></SelectAccountType>
        break
      case 1:
        view = <CreateAccountForm></CreateAccountForm>
        break
      case 2:
        view = <CreateUserForm></CreateUserForm>
        break
      default:
        view = <></>
        break
    }

    return view
  }

  return (
    <div>
      <div className="sub_header">
        <div className="back_icon">
          <BackArrow onClick={() => navigateTo(-1)}></BackArrow>
          <h2>Back</h2>
        </div>
      </div>
      <div className="flex flex-col justify-center">
        <div className="w-3/4 ml-auto mr-auto mt-10 mb-20">
          <Stepper
            steps={steps}
            activeStep={activeStep}
            onStepChange={handleStepChange}
          />
          {actionView(activeStep)}
        </div>
      </div>
    </div>
  )
}
