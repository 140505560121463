import React from 'react'
import { TagsList } from './TagList'

interface TagContainerProps {
  tagListTitle?: string
  tags?: string[] | number[] | null | string | number
  titleFontSize?: string
  titleBottomMargin?: string
  titleHorizontal?: boolean
  bold?: boolean
}

const TagContainer: React.FC<TagContainerProps> = ({
  tagListTitle,
  tags,
  titleFontSize,
  titleBottomMargin,
  titleHorizontal,
  bold = true,
}) => {
  return (
    <div
      className={`flex ${titleHorizontal ? 'flex-row' : 'flex-col'} ${titleHorizontal ? 'items-center' : 'items-start'} gap-1 self-stretch w-full relative flex-none h-[136px] max-h-fit`}
    >
      <div
        className={`relative w-fit font-work-sans ${bold ? 'font-semibold' : 'font-normal'} tracking-normal leading-5 whitespace-nowrap not-italic text-gray ${titleFontSize ? titleFontSize : 'text-sm'} ${titleBottomMargin} ${titleHorizontal ? 'mr-4' : undefined}`}
      >
        {tagListTitle}
      </div>
      <TagsList value={tags} />
    </div>
  )
}

export default TagContainer
