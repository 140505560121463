import * as React from 'react'
import useProfileStore from '../../../data/appState/profileStore'
import {
  ConnectionStatus,
  ConnectionType,
} from '../../../domain/types/Connection'
import {
  getConvoTitle,
  getConvoTitleByKeyAndProfiles,
  useConvoOrgs,
  useMyNetworkProfiles,
} from '../../../data/services/conversation/conversationService'
import { useEffect } from 'react'
import { ChatListItem } from './ChatListItem'
import { useParams } from 'react-router-dom'
import { List } from 'antd'
import useConversationsStore from '../../../data/appState/conversationsStore'
import { Conversation } from '../../../domain/types/Conversation'

export interface IChatListProps {
  chats: []
  onChatClick: (chatId: string) => void
}

export function ChatList() {
  const { profile, orgConnections, unReadConvoIds } = useProfileStore()

  const { filters, searchKey } = useConversationsStore()
  const { networkProfiles } = useMyNetworkProfiles()
  const { convoOrgs } = useConvoOrgs()

  const { type: paramfilterType } = useParams() // primary , requests

  const [chats, setChats] = React.useState<Conversation[]>([])

  useEffect(() => {
    if (networkProfiles.length > 0 && orgConnections.length > 0) {
      const chats = [...orgConnections]
        ?.map((convo) => {
          if (convo.lastMessage?.createdOn)
            convo.updatedOn = convo.lastMessage?.createdOn.toDate()

          if (convo.connectionType === ConnectionType.ORG_ORG) {
            convo.title = getConvoTitle(
              convo,
              profile,
              networkProfiles,
              convoOrgs,
            )
          }
          if (convo.connectionType === ConnectionType.USER_USER) {
            convo.title = getConvoTitleByKeyAndProfiles(
              convo.connectionKey,
              networkProfiles,
            )
          }

          convo.isUnread = unReadConvoIds.includes(convo.db_ref_id)
          convo.isPendingReq =
            convo.connectionType === ConnectionType.ORG_ORG &&
            convo.connectionStatus === ConnectionStatus.PENDING

          convo.isArchived = false
          if (
            convo.archivedState &&
            profile?.uid &&
            convo.archivedState[profile?.uid] === true
          )
            convo.isArchived = true

          return convo
        })
        .sort((a, b) => {
          let auDate = a.updatedOn
          let buDate = b.updatedOn

          if (!auDate) {
            return 1
          }
          if (!buDate) {
            return -1
          }

          if (auDate.toDate) {
            auDate = auDate.toDate()
          }
          if (buDate.toDate) {
            buDate = buDate.toDate()
          }

          return buDate?.getTime() - auDate?.getTime()
        })
      setChats(chats)
    }
  }, [orgConnections, profile, networkProfiles, convoOrgs, unReadConvoIds])

  let displayChats = [...chats]

  if (!paramfilterType || paramfilterType === 'primary') {
    displayChats = displayChats.filter((chat) => !chat.isPendingReq)
  }
  if (paramfilterType === 'requests') {
    displayChats = displayChats
      .filter((chat) => chat.isPendingReq)
      .filter((chat) => chat.createdByUid !== profile?.uid)
  }

  //Search key filter
  if (searchKey) {
    displayChats = displayChats.filter((chat) =>
      chat.title?.toLowerCase().includes(searchKey.toLowerCase()),
    )
  }

  const isFilterEnabled =
    filters.isUnread ||
    filters.isArchived ||
    filters.isDirect ||
    filters.isGroup
  if (isFilterEnabled) {
    displayChats = displayChats.filter((chat) => {
      let canInclude = false

      if (filters.isUnread && chat.isUnread) {
        canInclude = true // Include if it's unread and the unread filter is active
      }

      if (filters.isArchived && chat.isArchived) {
        canInclude = true // Include if it's archived and the archived filter is active
      }

      if (
        filters.isDirect &&
        chat.connectionType === ConnectionType.USER_USER
      ) {
        canInclude = true // Include if it's a direct chat and the direct filter is active
      }

      if (filters.isGroup && chat.connectionType === ConnectionType.ORG_ORG) {
        canInclude = true // Include if it's a group chat and the group filter is active
      }

      return canInclude
    })
  } else {
    displayChats = displayChats.filter((chat) => !chat.isArchived)
  }
  if (!displayChats || displayChats.length === 0) {
    return (
      <div className="no_conversations">
        <span>No conversations found</span>
      </div>
    )
  }

  return (
    <div>
      <List
        className="chat_lists slim_scrollbar w-[392px] max-md:w-full"
        itemLayout="horizontal"
        dataSource={displayChats}
        renderItem={(chat) => {
          return (
            <ChatListItem
              convo={chat}
              convoOrgs={convoOrgs}
              networkProfiles={networkProfiles}
            ></ChatListItem>
          )
        }}
      />
    </div>
  )
}
