import React, { useState } from 'react'
import { Avatar, Select } from 'antd'
import { BackArrow, LucideSend } from '../../common/utilComponents'
import {
  addMsgToConversation,
  createConvoByProfiles,
  upsertConvoParticipant,
  useMyNetworkProfiles,
} from '../../../data/services/conversation/conversationService'
//import { useMyInvitesProfiles } from '../../../data/services/Invites/InviteService'
import { Profile } from '../../../domain/types/Profile'
import useProfileStore from '../../../data/appState/profileStore'
import { useNavigate } from 'react-router-dom'
import toast from '../../common/Toast'
import useSiteStore from '../../../data/appState/siteStore'

const getInitChar = (name: string | undefined) => {
  return name?.charAt(0).toUpperCase() ?? 'U'
}

interface ComposeMessageProps {
  conversationId: string
}
const ComposeMessage: React.FC<ComposeMessageProps> = () => {
  const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>()

  const { setfullSiteLoader } = useSiteStore()
  const { profile } = useProfileStore()
  const [newMessage, setNewMessage] = useState<string>()
  const { networkProfiles } = useMyNetworkProfiles()
  const navigateTo = useNavigate()
  let myNetworkProfiles: Profile[] = []

  if (networkProfiles.length > 0) {
    myNetworkProfiles = [...networkProfiles]
  }

  function removeDuplicatesByUID(arr) {
    if (!arr.length) return []
    return [...new Map(arr.map((obj) => [obj.uid, obj])).values()]
  }

  const options = myNetworkProfiles.length
    ? [
        ...removeDuplicatesByUID(myNetworkProfiles).map((profile: Profile) => {
          return {
            value: profile.uid,
            label: (
              <div className="flex items-center">
                <Avatar className="mr-2 bg-white text-cool-black">
                  {getInitChar(profile.firstName + ' ' + profile.lastName)}
                </Avatar>
                <div>
                  <div className=" profile_name font-medium text-white text-ellipsis whitespace-nowrap w-fit max-sm:w-[180px] overflow-hidden">
                    {profile.displayName}
                  </div>
                  <small className="text-gray-400 text-ellipsis whitespace-nowrap w-fit max-sm:w-[180px] overflow-hidden">
                    {profile.organisationName}
                  </small>
                </div>
              </div>
            ),
          }
        }),
      ]
    : []

  const handleChange = (val: string[]) => {
    setSelectedProfileIds(val)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      sendNewMessage()
    }
  }

  function ensureArray(input) {
    return Array.isArray(input) ? input : [input]
  }

  const sendNewMessage = async () => {
    setfullSiteLoader(true)
    if (!newMessage?.trim() || !profile?.uid) {
      setfullSiteLoader(false)
      return
    }

    if (!ensureArray(selectedProfileIds)?.length) {
      setfullSiteLoader(false)
      toast.error('Please select a recipient')
      return
    }

    try {
      const selectedProfiles: Profile[] = ensureArray(selectedProfileIds)
        .map((id) => myNetworkProfiles.find((profile) => profile.uid === id))
        .filter((profile) => profile !== undefined) as Profile[]
      // Create a new conversation with the selected profiles
      const conversationId = await createConvoByProfiles(
        [...selectedProfiles, profile],
        profile.uid,
      )
      if (!conversationId) {
        return
      }
      // Add the message to the conversation
      await addMsgToConversation(conversationId, {
        text: newMessage,
        createdById: profile?.uid,
        createdByName: profile?.displayName,
      })

      setNewMessage('')
      if (profile) {
        try {
          // Add the selected profiles to the conversation
          await upsertConvoParticipant(conversationId, {
            profileId: profile.id,
            profileUid: profile.uid,
            profileName: profile.displayName ?? '',
            profilePic: profile.photoURL,
            lastMessageSent: new Date(),
            updatedOn: new Date(),
            title: profile.title,
          })
        } catch (e) {
          console.error('Error updating participant', e)
        }
      }
      setfullSiteLoader(false)
      navigateTo(`/chat/type/primary/${conversationId}`)
    } catch (error) {
      console.error('Error sending message', error)
      toast.error('Error sending message')
    }
    setfullSiteLoader(false)
  }

  return (
    <div className="bg-[#0F1113] rounded-tr-[8px]">
      <div
        className={`${profile?.orgType === 'COMPANY' ? 'single_select' : ''} compose-message px-4 max-sm:gap-2 max-sm:p-4 bg-[#191B1E] border-[#0C0E12] border-b-[1px] h-[77px] flex justify-between max-sm:justify-start items-center rounded-tr-[8px] max-sm:bg-[#0F1217] max-sm:border-[#1E2225]`}
      >
        <div className="back_icon sm:hidden">
          <BackArrow onClick={() => navigateTo(-1)} />
        </div>
        <div className="relative w-full  max-sm:w-[96%] max-sm:pr-4">
          <span className="text-gray text-[16px] absolute top-[9px] left-[0.5rem] z-[1]">
            To:
          </span>

          <Select
            mode={profile?.orgType === 'COMPANY' ? undefined : 'multiple'}
            showSearch
            className="relative w-full compose-message-select"
            allowClear={false}
            options={options}
            value={selectedProfileIds}
            onChange={handleChange}
            suffixIcon={null}
            optionLabelProp="label"
            dropdownStyle={{
              backgroundColor: '#1a1a1a',
            }}
            filterOption={(inputValue, option) => {
              let isFiltered = false

              const _profile: Profile | undefined = myNetworkProfiles.find(
                (profile) => profile.uid === option?.value,
              )
              if (
                _profile &&
                _profile.displayName
                  ?.toLowerCase()
                  .includes(inputValue.toLowerCase())
              ) {
                isFiltered = true
              }

              return isFiltered
            }}
          />
        </div>
      </div>
      <div className={'chat_window '}>
        <div className="chating_screen slim_scrollbar"></div>
        <div className="chat-input-div rounded-br-[8px] bg-[#0F1113] h-full">
          <textarea
            className="text-input bg-light-black h-11 placeholder:#a1a1a1"
            placeholder="Type a message"
            onKeyPress={handleKeyPress}
            value={newMessage}
            onChange={(e) => {
              setNewMessage(e.target.value)
            }}
          />
          <div className="lucide_send_icon">
            <LucideSend onClick={sendNewMessage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComposeMessage
