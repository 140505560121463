import { Link } from 'react-router-dom'
import { DEFAULT_IMG } from '../../common/constants'
import { Button } from 'antd'

export function ConvoOrgDetails(props) {
  const { orgData } = props

  if (!orgData) return <div> No Data </div>

  const detailItemValue = (value: string | number | string[] | number[]) => {
    if (typeof value == 'object' && value.length) {
      return value.map((item: string | number, index) => (
        <span key={index}>{(index ? ', ' : '') + item}</span>
      ))
    } else {
      return value
    }
  }

  return (
    <div
      key={orgData?.id}
      className={'request_user ' + orgData?.orgType}
      id={orgData?.id}
    >
      <div className="req_profile_card">
        <div className="card_profile">
          <img
            className="card_img"
            src={orgData?.imageUrl ? orgData.imageUrl : DEFAULT_IMG}
            alt="images"
          />
        </div>
        <div className="req_card">
          <div className="header_title">{orgData?.title} </div>
          <div
            className="entity-descriotion multine-ellipsis"
            dangerouslySetInnerHTML={{
              __html: orgData?.about,
            }}
          />
          <div className="table_list">
            {orgData?.detailsList?.map((dlItem) => {
              if (
                dlItem?.label &&
                dlItem?.value &&
                (!Array.isArray(dlItem?.value) ||
                  (Array.isArray(dlItem?.value) && dlItem?.value.length !== 0))
              ) {
                return (
                  <div key={`id-${dlItem.label}`} className="text-group">
                    <label>{dlItem?.label}</label>
                    <span>
                      {dlItem?.value ? detailItemValue(dlItem?.value) : ''} {}
                    </span>
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
          {orgData?.orgType?.toLowerCase() === 'realestate' ? (
            <></>
          ) : (
            <div className="btn_secondary">
              <Link
                to={`/${orgData?.orgType?.toLowerCase()}/Messenger/${orgData?.id}`}
              >
                <Button type="default">View Profile</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
