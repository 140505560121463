/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ConfigProvider, MenuProps, Modal, Dropdown, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCompanyById } from '../../../data/services/company/companyService'
import { Company } from '../../../domain/types/Company'
import {
  getProfileById,
  updateProfile,
} from '../../../data/services/profile/profileService'
import { getInvestmentFirmById } from '../../../data/services/investor/investorService'
import { getReSponsorById } from '../../../data/services/realEstate/realEstateService'

import { OrgType, Profile } from '../../../domain/types/Profile'
import { InvestmentFirm } from '../../../domain/types/Investor'
import { RealEstateSponsor } from '../../../domain/types/RealEstate'
import { OrgUsers } from '../../components/OrgUsers'
import { OrgSelection } from './Accounts'
import toast from '../../common/Toast'
import { updateActiveStateOrgByIdandType } from '../../../data/services/accounts/accountsService'
import useSiteStore from '../../../data/appState/siteStore'
import { OrgView } from '../OrgView'
import { BackArrow } from '../../common/utilComponents'

function AccountView({ selectedOrg }: { selectedOrg?: OrgSelection }) {
  const { setfullSiteLoader } = useSiteStore()
  const { setLoaderMessage } = useSiteStore()
  let { id: orgId } = useParams()
  const { filterValue } = useParams()
  let orgType = filterValue as OrgType
  if (!orgId && selectedOrg?.id) orgId = selectedOrg.id
  if (!orgType && selectedOrg?.filter) orgType = selectedOrg.filter as OrgType
  const [account, setAccount] = useState<
    Company | InvestmentFirm | RealEstateSponsor
  >()
  const [oranisationProfiles, setOranisationProfiles] = useState<Profile[]>()
  const [firmProfile, setFirmProfile] = useState<Profile>()
  const [toggleModal, setToggleModal] = useState<boolean>(false)
  const [viewProfileModal, setViewProfileModal] = useState<boolean>(false)
  const [isAccountDeactivated, setIsAccountDeactivated] =
    useState<boolean>(false)

  const items: MenuProps['items'] = [
    {
      label: (
        <button
          className="w-full text-center border-0"
          onClick={() => setToggleModal(true)}
        >
          {isAccountDeactivated && isAccountDeactivated == true
            ? 'Activate'
            : 'DeActivate'}
        </button>
      ),
      key: '1',
    },
  ]

  useEffect(() => {
    if (orgType && orgId) {
      if (orgType === 'COMPANY') {
        getCompanyById(orgId).then((data: Company) => {
          setAccount(data)
        })
      } else if (orgType === 'INVESTOR') {
        getInvestmentFirmById(orgId).then((data) => {
          setAccount(data)
        })
      } else if (orgType === 'REALESTATE') {
        getReSponsorById(orgId).then((data) => {
          setAccount(data)
        })
      }
    }
  }, [orgType, orgId])

  const onViewProile = () => {
    if (orgType && orgId) {
      setViewProfileModal(true)
    }
  }

  useEffect(() => {
    if (account?.createdById) {
      getProfileById(account?.createdById).then((response) => {
        if (response) {
          setFirmProfile(response)
        }
      })
    }
  }, [account])

  const updateProfileActiveStatus = async (
    canActivate: boolean,
    profile: Profile,
  ) => {
    try {
      const status = {
        userStatus: canActivate
          ? (profile.prevUserStatus ?? 'activated')
          : 'deactivated',
        prevUserStatus: profile.userStatus ?? '',
        db_ref_id: profile.db_ref_id,
      } as Partial<Profile>

      await updateProfile(status)
    } catch (error) {
      console.error(error)
    }
  }

  const updateAccountStatus = async (
    orgId: string,
    orgType: string,
    isDisabled: boolean,
  ) => {
    try {
      setLoaderMessage('Please wait')
      setfullSiteLoader(true)
      await updateActiveStateOrgByIdandType(orgId, orgType, isDisabled)
      if (oranisationProfiles && oranisationProfiles.length != 0)
        for (const profile of oranisationProfiles)
          await updateProfileActiveStatus(!isDisabled, profile)
      setIsAccountDeactivated(!isAccountDeactivated)
      toast.success(`Account ${isDisabled ? 'deactivated' : 'activated'}`)
      setfullSiteLoader(false)

      setToggleModal(false)
    } catch (error) {
      console.error(error)
      setToggleModal(false)
      setfullSiteLoader(false)
    }
  }

  const onListLoaded = (data) => {
    if (data) {
      setOranisationProfiles(data)
    } else setOranisationProfiles([])
  }

  useEffect(() => {
    if (oranisationProfiles && oranisationProfiles.length > 0) {
      const profiles = oranisationProfiles.filter(
        (o) => o.userStatus === 'deactivated',
      )
      if (profiles && profiles.length > 0) {
        setIsAccountDeactivated(true)
        // isAccountDeactivated = true
      } else {
        setIsAccountDeactivated(false)
        // isAccountDeactivated = false
      }
    }
  }, [oranisationProfiles])

  return (
    <div className="mt-6 body_layout">
      <ConfigProvider>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between ">
            <div className="flex flex-col">
              <div className="text-white text-[18px]">{account?.name}</div>

              <div
                className="text-yellow-green text-[14px] cursor-pointer"
                onClick={() => onViewProile()}
              >
                View Profile
              </div>
            </div>

            <Dropdown
              trigger={['click']}
              menu={{
                items,
              }}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button>Actions</Button>
            </Dropdown>
          </div>

          <div className="flex flex-col">
            <h2 className="block text-[20px] text-celadon-green">
              Account Information
            </h2>
            <div className="flex ">
              <div className="label-group">
                {!orgType ? (
                  <></>
                ) : (
                  <div className="mr-6">
                    <div className="text-[14px] text-gray font-semibold">
                      Account Type
                    </div>
                    <div className="text-[14px] text-white font-medium">
                      {orgType}{' '}
                    </div>
                  </div>
                )}
              </div>

              <div className="label-group">
                {!account?.name ? (
                  <></>
                ) : (
                  <div className="mr-6">
                    <div className="text-[14px] text-gray font-semibold">
                      Company Name
                    </div>
                    <div className="text-[14px] text-white font-medium">
                      {account?.name ?? '--'}
                    </div>
                  </div>
                )}
              </div>

              <div className="label-group">
                {account?.id ? (
                  <div className="mr-6">
                    <div className="text-[14px] text-gray font-semibold">
                      Account ID
                    </div>
                    <div className="text-[14px] text-white font-medium">
                      {account?.id}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <h2 className="block text-[20px] mt-4  text-celadon-green">
              Account Owner
            </h2>

            <div className="flex">
              <div className="label-group">
                {firmProfile?.firstName ? (
                  <div className="mr-6">
                    <div className="text-[14px] text-gray font-semibold">
                      First Name
                    </div>
                    <div className="text-[14px] text-white font-medium">
                      {firmProfile?.firstName}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="label-group">
                {firmProfile?.lastName ? (
                  <div className="mr-6">
                    <div className="text-[14px] text-gray font-semibold">
                      Last Name
                    </div>
                    <div className="text-[14px] text-white font-medium">
                      {firmProfile?.lastName}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="label-group">
                {firmProfile?.email ? (
                  <div className="mr-6">
                    <div className="text-[14px] text-gray font-semibold">
                      Email
                    </div>
                    <div className="text-[14px] text-white font-medium">
                      {firmProfile?.email}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="label-group">
                {firmProfile?.phoneNumber ? (
                  <div className="mr-6">
                    <div className="text-[14px] text-gray font-semibold">
                      Phone Number
                    </div>
                    <div className="text-[14px] text-white font-medium">
                      {firmProfile?.phoneNumber}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          {!orgId ? (
            <></>
          ) : (
            <div>
              <h2 className="block text-[20px] text-celadon-green">
                Account Users
              </h2>
              <OrgUsers orgId={orgId} onListLoaded={onListLoaded} />
            </div>
          )}
        </div>
      </ConfigProvider>
      <Modal
        className="w-full account-profile-modal"
        open={viewProfileModal}
        footer={null}
        closable={false}
        width={1300}
      >
        <div className="w-full text-white">
          <div className="flex items-center gap-2 mt-2 mb-6 cursor-pointer back_icon">
            <BackArrow onClick={() => setViewProfileModal(false)}></BackArrow>
            <h2>Back</h2>
          </div>
          <OrgView orgType={orgType} id={orgId} />
        </div>
      </Modal>
      <Modal
        className="modal-content"
        okText="Ok"
        onOk={() => {
          if (orgId) updateAccountStatus(orgId, orgType, !isAccountDeactivated)
        }}
        onCancel={() => setToggleModal(false)}
        centered
        open={toggleModal}
        width={1000}
      >
        <div className="text-white modal_body">
          Are you sure you want to
          {isAccountDeactivated ? ' activate' : ' deactivate'} {account?.name}?
        </div>
      </Modal>
    </div>
  )
}

export default AccountView
