import * as React from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input, Modal, Tabs } from 'antd'
import { getExploreData } from '../../data/services/explore/exploreService'
import { ExploreCardData } from '../../data/services/explore/ExploreCardData'
import ExploreCard from '../components/ExploreCard'
import { useEffect, useState, useRef } from 'react'
import { ProcessState } from '../common/types'
import { ShimmerSimpleGallery } from 'react-shimmer-effects'
import { Company } from '../../domain/types/Company'
import {
  ConnectionStatus,
  ConnectionType,
  ConversationType,
} from '../../domain/types/Connection'
import { RealEstateOpportunity } from '../../domain/types/RealEstateOpportunity'
import { ExpandedREOpportunity } from '../components/ExpandedREOppurtunityVIew'
import { ExpandedInvestmentFirmView } from '../components/ExpandedInvestmentFirmView'
import { InvestmentFirm } from '../../domain/types/Investor'
import {
  createConversation,
  getConvoByKey,
} from '../../data/services/conversation/conversationService'
import useExploreStore from '../../data/appState/exploreStore'
import { BackArrow, CloseIcon, FilterIcon } from '../common/utilComponents'
import toast from '../common/Toast'
import { OrgType } from '../../domain/types/Profile'
import ExploreFiltersView from '../components/ExploreFiltersView'
import { CompanyCard } from '../components/CompanyCard/CompanyCard'
import { getEndOfWeek, getStartOfWeek } from '../utils/formatters'
import { CompanyPageContent } from './Company/CompanyPageContents'
import { isMobile } from '../common/utils'
import { OnboardingModal } from '../components/OnboardingModal/OnboardingModal'
import CompanyIcon from '../../../assets/building-1.svg'
import RealEstateIcon from '../../../assets/buildings-1.svg'
import { ConvoMessage, ConvoParticipant } from '../../domain/types/Conversation'

type ConnectionsState = {
  [orgId: string]: ConnectionStatus
}

function defaultExploreType(orgType: string | undefined): string {
  orgType = orgType?.toLowerCase()
  if (orgType === 'company') {
    return 'investors'
  }
  if (orgType === 'realestate') {
    return 'investors'
  }
  if (orgType === 'investor') {
    return 'companies'
  }
  return 'companies'
}

function browseTitle(exploreType: string | undefined): JSX.Element {
  exploreType = exploreType?.toLowerCase()
  if (exploreType === 'companies') {
    return <div className="exp_title">Explore Companies</div>
  }
  if (exploreType === 'realestates') {
    return <div className="exp_title">Explore Real Estate</div>
  }
  if (exploreType === 'investors') {
    return <div className="exp_title">Explore Investors</div>
  }
  return <div className="exp_title">Explore</div>
}

export default function Explore() {
  const navigateTo = useNavigate()

  const { profile, orgConnections } = useProfileStore()
  const [listScrollYPostion, setlistScrollYPostion] = useState(0)
  const { orgId: paramOrgId, orgName: paramOrgName } = useParams()
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [searchKey, setSearchKey] = useState<string>('')
  const [searchKeyValue, setSearchKeyValue] = useState<string>('') //need to change this approach
  const [exploreType, setExploreType] = useState<string>(
    defaultExploreType(profile?.orgType),
  )
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleKeyDown = (e) => {
    const value = e.currentTarget.value
    setSearchKeyValue(value)

    // Clear the previous timer
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }

    // Set a new timer
    debounceTimeout.current = setTimeout(() => {
      setSearchKey(value)
    }, 300) // 300 ms delay
  }

  const maxRequestsPerWeek = 10

  const [exploreData, setExploreData] = React.useState<ExploreCardData[]>([])
  const [orgConnectionStates, setOrgConnectionStates] =
    React.useState<ConnectionsState>({})
  const [selectedCard, setSelectedCard] =
    React.useState<ExploreCardData | null>(null)
  const [requestingCard, setRequestingCard] =
    React.useState<ExploreCardData | null>(null)
  const [processState, setProcessState] = useState<ProcessState>(
    ProcessState.Idle,
  )

  const today = new Date()
  const startOfWeek = getStartOfWeek(today)
  const endOfWeek = getEndOfWeek(startOfWeek)

  const [requestMessage, setRequestMessage] = useState<string>('')
  const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(false)
  const { selectedOptions, clearSelectedOptions } = useExploreStore()
  const [filteredExploreData, setFilteredExploreData] = React.useState<
    ExploreCardData[]
  >([])
  const [isRequesting, setIsRequesting] = useState<boolean>(false)

  const closeModal = () => {
    setRequestMessage('')
    setRequestingCard(null)
  }

  const loadExploreData = (expType) => {
    setProcessState(ProcessState.Loading)
    getExploreData(expType)
      .then((data) => {
        setExploreData(data)

        setProcessState(ProcessState.Success)
      })
      .catch((e) => {
        setProcessState(ProcessState.Failed)
        console.error(e)
      })
  }

  useEffect(() => {
    if (exploreData.length && (selectedOptions.length || searchKey)) {
      let filteredData = exploreData

      if (selectedOptions.length) {
        filteredData = exploreData.filter((cardData) => {
          //TODO: Refactor this to a Filter function
          // TODO: Refactor this to support AND Condition

          const { orgData } = cardData
          const orgDataValues = Object.values(orgData)
          let isSelected = false
          for (const value of orgDataValues) {
            if (typeof value === 'string' || typeof value === 'number') {
              const index = selectedOptions.findIndex(
                (option) => option.value === value,
              )
              isSelected = index > -1
            }

            // Check if value is string array
            if (Array.isArray(value) && value.length > 0) {
              const index = value.findIndex(
                (v) =>
                  selectedOptions.findIndex((option) => option.value === v) >
                  -1,
              )
              isSelected = index > -1
            }

            if (isSelected) break
          }
          return isSelected
        })
      }
      if (searchKey) {
        filteredData = filteredData.filter((cardData) => {
          const { title, detailsList } = cardData

          // Function to normalize strings by removing spaces and special characters
          const normalize = (str) => str.toLowerCase().replace(/[\s\W_]+/g, '')

          const normalizedSearchKey = normalize(searchKey.toString())
          const searchKeyNumber = !isNaN(Number(searchKey))
            ? Number(searchKey)
            : null

          // Normalize and compare the title
          let isMatch = normalize(title).includes(normalizedSearchKey)

          if (
            !isMatch &&
            Array.isArray(detailsList) &&
            detailsList.length > 0
          ) {
            // Check if any `detailsList` item's value matches the normalized searchKey
            isMatch = detailsList.some((detail) => {
              const { value } = detail

              // Handle string values
              if (typeof value === 'string') {
                return normalize(value).includes(normalizedSearchKey)
              }

              // Handle numeric values
              if (typeof value === 'number' && searchKeyNumber !== null) {
                return value === searchKeyNumber
              }

              // Handle array of strings or numbers
              if (Array.isArray(value)) {
                return value.some(
                  (val) =>
                    (typeof val === 'string' &&
                      normalize(val).includes(normalizedSearchKey)) ||
                    (typeof val === 'number' &&
                      searchKeyNumber !== null &&
                      val === searchKeyNumber),
                )
              }

              return false
            })
          }

          return isMatch
        })
      }

      setFilteredExploreData(filteredData)
    } else {
      setFilteredExploreData(exploreData)
    }
  }, [selectedOptions, exploreData, searchKey])

  useEffect(() => {
    if (orgConnections?.length) {
      const initState = {} as ConnectionsState
      const orgConnectionsState = orgConnections
        .filter((oc) => oc.connectionType === 'ORG_ORG')
        .reduce((acc, conn) => {
          const otherOrgId =
            conn.fromOrgId === profile?.organisationId
              ? conn.toOrgId
              : conn.fromOrgId

          acc[otherOrgId] = conn.connectionStatus
          return acc
        }, initState)

      setOrgConnectionStates(orgConnectionsState)
    }
  }, [orgConnections, profile?.organisationId])

  useEffect(() => {
    loadExploreData(exploreType)
  }, [exploreType])

  useEffect(() => {
    if (paramOrgId && paramOrgName) {
      const cardData = exploreData.find((card) => card.id === paramOrgId)
      if (cardData) {
        setSelectedCard(cardData)
      } else {
        setSelectedCard(null)
      }
    } else {
      setSelectedCard(null)
    }
  }, [paramOrgId, paramOrgName, exploreData])

  useEffect(() => {
    if (!profile?.hasSeenOnboardingVideo && profile?.orgType === 'COMPANY') {
      setTimeout(() => {
        setShowOnboardingModal(true)
      }, 1000)
    }
  }, [profile])

  const handleConnect = async (cardData: ExploreCardData) => {
    setIsRequesting(true)
    if (!profile) return

    try {
      const {
        displayName: createdByName,
        uid: createdByUid,
        orgType: fromOrgType,
        organisationId: fromOrgId,
        organisationName: fromOrgName,
      } = profile

      const myConnections = orgConnections.filter((o) => {
        let createdOn = o.createdAt ?? o.createdOn

        if (createdOn?.toDate) {
          createdOn = createdOn.toDate()
        }
        return (
          o.createdByUid === createdByUid &&
          createdOn > startOfWeek &&
          createdOn <= endOfWeek
        )
      })

      const maxRequestsReached: boolean =
        myConnections?.length >= maxRequestsPerWeek

      if (maxRequestsReached && profile?.orgType !== 'INVESTOR') {
        toast.error(
          'Weekly Limit Reached',
          `Our platform allows only ${maxRequestsPerWeek} requests per week`,
        )
        setRequestingCard(null)
        return
      }

      // let id = profile.organisationId + "_" + cardData.id;

      let { id: toOrgId, title: toOrgName } = cardData
      const { orgType: toOrgType } = cardData

      if (toOrgType === 'REALESTATE') {
        const orgData: RealEstateOpportunity =
          cardData.orgData as RealEstateOpportunity
        toOrgId = orgData?.sponserId ?? ''
        toOrgName = cardData.orgData?.name ?? ''
      }

      const connectionKey = [fromOrgId, toOrgId].sort().join('_')
      const initState = {
        createdByUid,
        createdByName,
        fromOrgId,
        fromOrgType,
        fromOrgName,
        toOrgId,
        toOrgType,
        toOrgName,
        connectionKey,
        connectionType: ConnectionType.ORG_ORG,
        targetId: cardData.id,
        type: ConversationType.GROUP,
        [toOrgId]: true,
        [fromOrgId ?? '']: true,
      }

      const convoMessage: ConvoMessage = {
        text: requestMessage,
        createdById: profile?.uid ?? '',
      }

      const convoParticipant: ConvoParticipant = {
        profileId: profile.id ?? '',
        profileUid: profile?.uid ?? '',
        profileName: profile.displayName ?? '',
        profilePic: profile.photoURL ?? '',
      }

      await createConversation(
        initState,
        convoMessage,
        convoParticipant,
        connectionKey,
      )
      setRequestingCard(null)
      //vmc
      setRequestMessage('')
    } catch (error) {
      console.error('Error sending connection request:', error)
      toast.error('Error sending connection request')
    } finally {
      setIsRequesting(false)
    }
  }

  const gotoConversation = (cardData: ExploreCardData) => {
    let orgId = cardData.id
    if (cardData.orgType === 'REALESTATE') {
      const orgData: RealEstateOpportunity =
        cardData.orgData as RealEstateOpportunity
      if (orgData?.sponserId) orgId = orgData?.sponserId
    }

    const connectionKey = [orgId, profile?.organisationId].sort().join('_')
    console.log(connectionKey)
    getConvoByKey(connectionKey, ConnectionType.ORG_ORG).then((convos) => {
      if (convos.length > 0) {
        navigateTo(`/chat/${convos[0].db_ref_id}`)
      }
    })
  }

  const cardStatusAction = (cardData?: ExploreCardData | null): JSX.Element => {
    if (!cardData) return <></>

    let orgId = cardData.id
    if (cardData.orgType === 'REALESTATE') {
      const orgData: RealEstateOpportunity =
        cardData.orgData as RealEstateOpportunity
      if (orgData?.sponserId) orgId = orgData?.sponserId
    }

    const cardStatus = orgConnectionStates[orgId]

    const isNotConnected =
      !cardStatus || cardStatus === ConnectionStatus.NOT_CONNECTED

    if (isNotConnected) {
      return (
        <Button
          onClick={(e) => {
            e.stopPropagation()
            setRequestingCard(cardData)
          }}
        >
          Message
        </Button>
      )
    } else {
      return (
        <div
          className="card_status"
          onClick={(e) => {
            e.stopPropagation()
            gotoConversation(cardData)
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault()
              gotoConversation(cardData)
            }
          }}
        >
          <span>{cardStatus}</span>
        </div>
      )
    }
  }

  const setExpandedCardView = (cardData) => {
    const route = isAdmin
      ? `/admin/explore/${exploreType}/${cardData.id}/${encodeURIComponent(cardData.title)}`
      : `/explore/${exploreType}/${cardData.id}/${encodeURIComponent(cardData.title)}`
    navigateTo(route)
    setSelectedCard(cardData)
  }

  const ProcessingListView = () => {
    switch (processState) {
      case ProcessState.Loading:
        return <ShimmerSimpleGallery card imageHeight={300} caption />
      case ProcessState.Failed:
        return <h1>Failed to load data</h1>
      case ProcessState.Success:
        return (
          <div className="layout-grid">
            <OnboardingModal showOnboardingModal={showOnboardingModal} />
            {filteredExploreData.length > 0 ? (
              filteredExploreData.map((cardData) => {
                const connectionStatus = orgConnectionStates[cardData.id]
                return cardData.orgType === 'INVESTOR' ||
                  cardData.orgType === 'REALESTATE' ? (
                  <ExploreCard
                    key={cardData.id}
                    cardData={cardData}
                    cardStatusAction={() => cardStatusAction(cardData)}
                    onCardSelect={(cardData) => {
                      setlistScrollYPostion(window.scrollY)
                      window.scrollTo(0, 0)
                      setExpandedCardView(cardData)
                    }}
                  />
                ) : (
                  <CompanyCard
                    cardData={cardData}
                    connectionStatus={connectionStatus}
                    sendRequestToConnect={() => setRequestingCard(cardData)}
                    gotoConversation={() => gotoConversation(cardData)}
                    onCardSelect={(cardData) => {
                      setlistScrollYPostion(window.scrollY)
                      window.scrollTo(0, 0)
                      setExpandedCardView(cardData)
                    }}
                  />
                )
              })
            ) : (
              <h1>
                {' '}
                No {exploreType === 'realestates'
                  ? 'Real Estate'
                  : exploreType}{' '}
                Found{' '}
              </h1>
            )}
          </div>
        )
      default:
        return <h1>Loading.. </h1>
    }
  }

  const ExpandedCardView = (): JSX.Element => {
    let resView = <></>
    const company = selectedCard?.orgData as Company
    const re_opp = selectedCard?.orgData as RealEstateOpportunity
    const invFirm = selectedCard?.orgData as InvestmentFirm

    switch (exploreType) {
      case 'companies':
        resView = <CompanyPageContent company={company} />
        break

      case 'realestates':
        resView = (
          <ExpandedREOpportunity
            opportunity={re_opp}
            cardStatusAction={() => cardStatusAction(selectedCard)}
          />
        )
        break

      case 'investors':
        resView = (
          <ExpandedInvestmentFirmView
            investmentFirm={invFirm}
            cardStatusAction={() => cardStatusAction(selectedCard)}
          ></ExpandedInvestmentFirmView>
        )
        break

      default:
        resView = <></>
        break
    }

    return resView
  }

  const listWrapClass =
    selectedCard != null ? 'list-wrap single-column' : 'list-wrap'

  const profileOrgType = profile?.orgType as OrgType
  const isAdmin = profileOrgType === 'ADMIN'
  const connectionStatus = selectedCard && orgConnectionStates[selectedCard.id]
  const isNotConnected =
    !connectionStatus || connectionStatus === ConnectionStatus.NOT_CONNECTED

  const tabs = [
    {
      key: 'companies',
      hidden: false,
      label: (
        <div className="flex items-center space-x-2">
          <img
            src={CompanyIcon}
            alt="Companies"
            className="w-5 h-5"
            style={{
              filter:
                exploreType === 'companies'
                  ? 'brightness(0) saturate(100%) invert(100%) sepia(84%) saturate(2%) hue-rotate(48deg) brightness(104%) contrast(100%)'
                  : 'none',
            }}
          />
          <span
            className={`${
              exploreType === 'companies' ? 'text-white' : 'text-gray'
            }`}
          >
            Companies
          </span>
        </div>
      ),
    },
    {
      key: 'realestates',
      hidden: false,
      label: (
        <div className="flex items-center space-x-2">
          <img
            src={RealEstateIcon}
            alt="Real Estate"
            className="w-5 h-5 mr-2"
            style={{
              filter:
                exploreType === 'realestates'
                  ? 'brightness(0) saturate(100%) invert(100%) sepia(84%) saturate(2%) hue-rotate(48deg) brightness(104%) contrast(100%)'
                  : 'none',
            }}
          />
          <span
            className={`${
              exploreType === 'realestates' ? 'text-white' : 'text-gray'
            }`}
          >
            Real Estate
          </span>
        </div>
      ),
    },
    {
      key: 'investors',
      hidden: !isAdmin,
      label: (
        <div className="flex items-center space-x-2">
          <img
            src={CompanyIcon}
            alt="Investors"
            className="w-5 h-5"
            style={{
              filter:
                exploreType === 'investors'
                  ? 'brightness(0) saturate(100%) invert(100%) sepia(84%) saturate(2%) hue-rotate(48deg) brightness(104%) contrast(100%)'
                  : 'none',
            }}
          />
          <span
            className={`${
              exploreType === 'investors' ? 'text-white' : 'text-gray'
            }`}
          >
            Investors
          </span>
        </div>
      ),
    },
  ]

  return (
    <div className="body_layout">
      {!selectedCard ? (
        <>
          <div className="explore_header">
            <div className="filter-wrap">
              <div className="switch-wrap">
                {profileOrgType === 'INVESTOR' || isAdmin ? (
                  <Tabs
                    defaultActiveKey={exploreType}
                    items={tabs.filter((tab) => !tab.hidden)}
                    onChange={(key) => setExploreType(key)}
                  />
                ) : (
                  browseTitle(exploreType)
                )}
              </div>

              <div className="search_div">
                <Input
                  className="search-input-field"
                  placeholder={`Search ${exploreType === 'realestates' ? 'Real Estate' : exploreType}`}
                  type="text"
                  allowClear
                  name=""
                  id=""
                  value={searchKeyValue}
                  onChange={handleKeyDown}
                />
                <div
                  className="filter_icon"
                  onClick={() => {
                    setShowFilters(!showFilters)
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setShowFilters(!showFilters)
                    }
                  }}
                >
                  {selectedOptions.length !== 0 ? (
                    <div className="filter_count">
                      {selectedOptions?.length}
                    </div>
                  ) : (
                    <></>
                  )}

                  <FilterIcon />
                </div>

                <div
                  className={`filter-panel ${showFilters ? 'show' : 'hide'}`}
                >
                  <div className="filter_panel_flex">
                    <div className="filter_header">
                      <h3>Filters</h3>
                      <CloseIcon
                        onClick={() => {
                          setShowFilters(false)
                        }}
                      />
                    </div>

                    {exploreType && exploreData?.length ? (
                      <ExploreFiltersView
                        eploreCards={exploreData}
                        exploreType={exploreType}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="filter_buttons">
                      <Button
                        type="text"
                        className="reset-btn"
                        onClick={() => {
                          clearSelectedOptions()
                        }}
                      >
                        Reset
                      </Button>

                      <Button
                        type="primary"
                        className="save-btn"
                        onClick={() => {
                          setShowFilters(false)
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="card_container">
        <div className={listWrapClass}>
          {
            /* Results Cards List */
            ProcessingListView()
          }

          {
            /* Selected Card */

            !selectedCard ? (
              <></>
            ) : (
              <div className="relative">
                <div className="sticky top-[80px] z-20 flex justify-between items-center px-4 py-2 shadow-md rounded-md bg-cool-black">
                  <div className="flex items-center gap-2">
                    <BackArrow
                      onClick={() => {
                        setTimeout(() => {
                          if (listScrollYPostion) {
                            window.scrollTo(0, listScrollYPostion + 50)
                          }
                        }, 500)
                        navigateTo(-1)
                      }}
                    />
                    <h2>Back</h2>
                  </div>

                  {/* Right Section: Message Button or Connection Status */}
                  {!isMobile && (
                    <div>
                      {isNotConnected ? (
                        <Button
                          className="py-2 px-4 font-medium text-base leading-7 text-black bg-yellow-400 hover:bg-yellow-500"
                          onClick={() => setRequestingCard(selectedCard)}
                          type="primary"
                        >
                          Message
                        </Button>
                      ) : (
                        <div
                          className="cursor-pointer"
                          onClick={() => gotoConversation(selectedCard)}
                          role="button"
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault()
                              gotoConversation(selectedCard)
                            }
                          }}
                        >
                          <span className="text-white">{connectionStatus}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Scrollable Content */}
                <div className="slide-view overflow-y-auto mt-4">
                  {ExpandedCardView()}
                  {isMobile && (
                    <div className="flex items-center justify-center"></div>
                  )}
                </div>
              </div>
            )
          }
        </div>
      </div>
      {/* request confirmation modal */}

      <div>
        <Modal
          className="popup_message"
          open={requestingCard != null}
          title={'Request To Connect With ' + requestingCard?.title}
          onOk={closeModal}
          onCancel={closeModal}
          footer={[
            <Button
              key="back"
              style={{ width: '100%' }}
              type="default"
              onClick={closeModal}
            >
              Cancel
            </Button>,
            <Button
              style={{ width: '100%' }}
              key="submit"
              type="primary"
              loading={isRequesting}
              onClick={() => {
                if (requestingCard) {
                  handleConnect(requestingCard)
                }
              }}
            >
              Send
            </Button>,
          ]}
        >
          <p className="paragraph">
            To connect with this company, send them a message request. <br />{' '}
            They will need to accept your request to start a conversation.
          </p>

          <div className="request_text_box">
            <label htmlFor="message"> Message</label>
            <textarea
              name="message"
              id="message"
              placeholder="Enter a message..."
              //VMC
              value={requestMessage}
              onChange={(e) => {
                setRequestMessage(e.target.value)
              }}
            ></textarea>
          </div>
        </Modal>
      </div>
    </div>
  )
}
