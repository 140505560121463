import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { PdfToImagesUploader } from '../../v1/presentation/components/PdfToImagesUploader'
import {
  Company,
  CompanyFile,
  CompanyFileCategories,
} from '../../v1/domain/types/Company'
import { DeleteIcons } from '../../v1/presentation/common/utilComponents'
import { formatBytes } from '../../v1/data/services/explore/converters'
import { FileIcon, defaultStyles } from 'react-file-icon'
import { AccountInfo } from './CompanyOnboarding'
import { isMobile } from '../../v1/presentation/common/utils'

interface PitchDeckSectionProps {
  setPitchDeckImages: (images: string[]) => void
  setCompanyFiles: (files: CompanyFile[]) => void
  form: FormInstance<AccountInfo>
  orgId?: string
  company?: Company
  companyFiles?: CompanyFile[]
}

export const PitchDeckSection: React.FC<PitchDeckSectionProps> = ({
  setPitchDeckImages,
  setCompanyFiles,
  form,
  orgId,
  company,
  companyFiles,
}) => {
  const pdFiles = companyFiles?.filter(
    (f) => f.category === CompanyFileCategories.pitchDeck,
  )

  useEffect(() => {
    if (company) {
      setCompanyFiles(company.companyFiles ?? [])
    }
  }, [company, setCompanyFiles])

  const addFileToCompany = async (
    fileCategory: string,
    file: { url: string; size?: number; images?: string[] },
    extractedData?: string,
  ): Promise<void> => {
    const oFiles = companyFiles && companyFiles?.length ? [...companyFiles] : []

    if (fileCategory === CompanyFileCategories.pitchDeck) {
      setPitchDeckImages?.(file.images ?? [])
      form.setFieldsValue({ pitchDeckImageFiles: file.images })
    }

    if (extractedData) {
      try {
        const parsedData = JSON.parse(extractedData)
        const fieldsToUpdate: Partial<AccountInfo> = {}

        // Map the extractedData to form fields
        fieldsToUpdate.companyName = parsedData.companyName || ''
        fieldsToUpdate.fundingRound =
          Array.isArray(parsedData.fundingRound) &&
          parsedData.fundingRound.length
            ? parsedData.fundingRound[0]
            : ''
        fieldsToUpdate.offeringType = Array.isArray(parsedData.offering)
          ? parsedData.offering
          : []
        fieldsToUpdate.minimumInvestment = parsedData.minimumInvestment
          ? Number(parsedData.minimumInvestment)
          : 0
        fieldsToUpdate.maximumInvestment = parsedData.MaximumInvestment
          ? parsedData.MaximumInvestment
          : 0 // Fixed handling here
        fieldsToUpdate.companyFounded = parsedData.companyFoundedYear || ''
        fieldsToUpdate.businessModel = Array.isArray(parsedData.businessModel)
          ? parsedData.businessModel
          : []
        fieldsToUpdate.employeeCount = parsedData.employeeCount
          ? Number(parsedData.employeeCount)
          : 0
        fieldsToUpdate.companyLocation = parsedData.companyLocation || ''
        fieldsToUpdate.industryFocus = Array.isArray(parsedData.industryFocus)
          ? parsedData.industryFocus
          : []
        fieldsToUpdate.valuation = parsedData.preMoneyValuation || ''
        fieldsToUpdate.raisedToDate =
          parsedData.moneyRaisedToDate &&
          !isNaN(Number(parsedData.moneyRaisedToDate))
            ? Number(parsedData.moneyRaisedToDate)
            : 0
        fieldsToUpdate.annualRevenue = parsedData.annualRevenue || ''
        fieldsToUpdate.useOfFunds = Array.isArray(parsedData.useOfFunds)
          ? parsedData.useOfFunds
          : []
        fieldsToUpdate.valueProposition = parsedData.valueProposition || ''

        // Set the form fields
        form.setFieldsValue(fieldsToUpdate)
      } catch (error) {
        console.error('Error parsing extractedData:', error)
      }
    }

    setCompanyFiles?.([...oFiles, { category: fileCategory, ...file }])
  }

  const getFileCard = (file: CompanyFile) => {
    const url = file.url ? decodeURIComponent(file.url) : ''
    const filePath = url.split('?')[0],
      fileExt = filePath.split('.').pop(),
      fileName = filePath.split('/').pop()?.split('--').pop()

    const iconStyles = defaultStyles[fileExt] ? defaultStyles[fileExt] : {}
    return (
      <div className="doc_files">
        <div className="file_icon">
          <FileIcon extension={fileExt} {...iconStyles} />
        </div>
        <div className="load_preview">
          <div className="file_name">{fileName}</div>
          <div>{formatBytes(file.size)}</div>
        </div>
        <div className="delete_icon">
          <DeleteIcons
            style={{ color: 'red' }}
            onClick={(e) => {
              e.preventDefault()
              const newFiles = companyFiles?.filter((cf) => cf.url !== file.url)
              setCompanyFiles?.([...(newFiles ?? [])])
              form.setFieldsValue({
                pitchDeckImageFiles: (newFiles ?? [])
                  .map((file) => file.url)
                  .filter((url): url is string => !!url),
              })
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center">
      <div className={`${isMobile ? 'w-80' : undefined}`}>
        <Form.Item
          label="Pitch Deck"
          name="pitchDeckImageFiles"
          valuePropName="value"
          rules={[{ required: true, message: 'Please upload a file!' }]}
          labelCol={{ style: { textAlign: 'left', fontSize: '18px' } }}
          preserve={true}
        >
          {!pdFiles?.length ? (
            <PdfToImagesUploader
              orgId={orgId}
              onUploadComplete={({ pdfFile, images, size, extractedData }) => {
                addFileToCompany(
                  CompanyFileCategories.pitchDeck,
                  {
                    url: pdfFile,
                    images,
                    size,
                  },
                  extractedData,
                )
              }}
            />
          ) : (
            <div className="edit-docs-view">
              {pdFiles?.map((file) => (
                <div className="w-100" key={file.url}>
                  {getFileCard(file)}
                </div>
              ))}
            </div>
          )}
        </Form.Item>
      </div>
    </div>
  )
}
