import * as React from 'react'
import useProfileStore from '../../data/appState/profileStore'
import { Col, Row } from 'antd'
import { useParams } from 'react-router-dom'
import { ChatWindow } from '../components/Chat/ChatWindow'
import { ConnectionStatus, ConnectionType } from '../../domain/types/Connection'
import { ChatTabs } from '../components/Chat/ChatTabs'
import ComposeMessage from '../components/Chat/ComposeMessage'

export function Chat() {
  const { orgConnections, unReadConvoIds, profile } = useProfileStore()
  const { id: paramConvoId } = useParams()

  let conversationId: string | null = null
  if (paramConvoId) {
    conversationId = paramConvoId
  }
  const pendingRequests = [...orgConnections].filter(
    (convo) =>
      convo.connectionType === ConnectionType.ORG_ORG &&
      convo.connectionStatus === ConnectionStatus.PENDING &&
      convo.createdByUid !== profile?.uid,
  )
  let unreadcount = 0
  if (unReadConvoIds?.length > 0) {
    unreadcount = unReadConvoIds.filter(
      (id) =>
        pendingRequests.findIndex((pending) => pending.db_ref_id === id) === -1,
    ).length
  }

  return (
    <div
      className={
        paramConvoId === undefined
          ? 'mv-no-convo chat_row max-sm:border-0 border border-[#1E2225] rounded-[8px] shadow-[0px 0px 10px 10px rgba(0, 0, 0, 0.25), 0px 0px 5px 0px rgba(87, 87, 87, 0.10) inset;] mt-10'
          : 'mv-convo-selected chat_row  max-sm:border-0 border border-[#1E2225] rounded-[8px] shadow-[0px 0px 10px 10px rgba(0, 0, 0, 0.25), 0px 0px 5px 0px rgba(87, 87, 87, 0.10) inset;] mt-10'
      }
    >
      <Row gutter={[16, 0]} style={{ margin: '0px' }}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={11}
          style={{ paddingLeft: '0px' }}
        >
          <ChatTabs
            unreadCount={unreadcount ?? 0}
            pendingCount={pendingRequests?.length}
          ></ChatTabs>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={13}
          style={{ paddingRight: '0px' }}
        >
          {conversationId ? (
            <div>
              {conversationId.toLowerCase() === 'new' ? (
                <ComposeMessage conversationId={conversationId} />
              ) : (
                <ChatWindow conversationId={conversationId} />
              )}
              {/* <ComposeMessage conversationId={conversationId} /> */}
            </div>
          ) : (
            <div className="no_messages bg-[#0F1113] flex items-center justify-center h-full rounded-tr-[8px] rounded-br-[8px]">
              <span>Please select a conversation</span>{' '}
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}
